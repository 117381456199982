<template>
  <q-dialog v-model="isOpenModal" @hide="tab = ''">
    <q-card :style="{ 'max-width': '80vw' }">
      <q-tabs v-model="tab" class="text-teal">
        <q-tab v-for="item in tabs" :key="item.name" :name="item.name" :label="item.label" />
      </q-tabs>
      <q-tab-panels
        v-model="tab"
        animated
      >
        <q-tab-panel name="user">
          <user-detail :user="data?.data?.data" @on-update="updateUserModal.toggleModal($event, activeUser)" />
          <update-user-modal ref="updateUserModal" @on-update="updateUserData" />
        </q-tab-panel>

        <q-tab-panel name="workspaces">
          <user-workspaces v-if="data?.data?.length && tab === 'workspaces'" :items="data?.data ?? []" />
        </q-tab-panel>

        <q-tab-panel name="projects">
          <user-projects v-if="data?.data?.length && tab === 'projects'" :items="data?.data ?? []" />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, defineExpose, defineEmits, watch } from 'vue';
import { apiConstants, requests } from '@/api';
import { useLoader } from '@/composables/useLoader.js';
import UserDetail from '@/components/UserData/UserDetail.vue';
import UserProjects from '@/components/UserData/UserProjects.vue';
import UserWorkspaces from '@/components/UserData/UserWorkspaces.vue';
import UpdateUserModal from '@/components/Modals/UpdateUserModal.vue';

const isOpenModal = ref(false);
const updateUserModal = ref(null);

const emit = defineEmits(['on-update-user', 'remove-user']);

const tabs = [
  { label: 'Пользователь', name: 'user' },
  { label: 'Пространства', name: 'workspaces' },
  { label: 'Проекты', name: 'projects' }
];

const loader = useLoader();
const tab = ref('');
const activeUser = ref(null);

const data = ref();

const fetchData = async () => {
  try {
    loader.show();

    data.value = await requests.get(apiConstants.USERS.GET_USER_DATA(activeUser.value.id), { params: { datatype: tab.value } });

    if (tab.value === 'user') {
      activeUser.value = data.value.data.data;
    }
  } catch {
  } finally {
    loader.hide();
  }
};

watch(tab, () => {
  if (isOpenModal.value) {
    fetchData();
  }
});

const toggleModal = (activeTab, user) => {
  isOpenModal.value = !isOpenModal.value;
  tab.value = activeTab;
  activeUser.value = user;
  data.value = null;
};

const updateUserData = async ({ event, response }) => {
  try {
    switch (event) {
      case 'addAdmin': {
        data.value = { data: { data: response } };
        emit('on-update-user');

        break;
      }

      case 'removeAdmin': {
        data.value = { data: { data: response } };
        emit('on-update-user');

        break;
      }

      case 'editUserModal': {
        data.value = { data: { data: response } };
        emit('on-update-user');

        break;
      }

      case 'deleteUser': {
        toggleModal();
        emit('on-update-user');

        break;
      }
    }
  } catch {
  }
};

defineExpose({
  toggleModal
});
</script>
