<template>
  <q-table :rows="items" :columns="columns" row-key="name" />
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
  items: {
    type: Array,
    required: true
  }
});

const columns = [
  { name: 'id', label: 'id', field: 'id', align: 'left' },
  { name: 'title', label: 'Название', field: 'title', align: 'left' },
  { name: 'alias', label: 'Alias', field: 'alias', align: 'left' },
  { name: 'users_count', label: 'Пользователи', field: 'users_count', align: 'center' },
  { name: 'projects_count', label: 'Проекты', field: 'projects_count', align: 'center' },
  { name: 'date', label: 'Дата создания', field: 'date', align: 'left' },
  { name: 'subscription', label: 'Подписка', field: (row) => row.subscription?.isActive ? `Активна до ${row.subscription?.finish || row.subscription.finishTrial}` : 'Не активна', align: 'left' }
];
</script>
