import axios from 'axios';
import { getToken } from '@/utils/token';
import apiConstants from './api-constants';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
});

instance.interceptors.response.use(
  (response) => {
    if (
      response.headers['content-type'] === 'text/html; charset=UTF-8' &&
          response.request.responseURL
    ) {
      window.location.href = response.request.responseURL;
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const setTokenInHeaders = (headers) => {
  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

const requests = {
  get (url, options = {}) {
    const headers = setTokenInHeaders({});
    // , queryParams = null
    return instance.get(url, { ...options, headers });
    // if (!queryParams) {
    //  return instance.get(url)
    // }
    // return instance.get(url, { params: axiosParams(queryParams) })
  },

  post (url, data) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    if (data instanceof FormData) {
      config.headers = {
        'Content-Type': 'multipart/form-data'
      };
    }
    config.headers = setTokenInHeaders(config.headers);
    return instance.post(url, data, config);
  },

  put (url, data) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    if (data instanceof FormData) {
      config.headers = {
        'Content-Type': 'multipart/form-data'
      };
    }
    config.headers = setTokenInHeaders(config.headers);
    return instance.put(url, data, config);
  },

  patch (url, data) {
    const config = { headers: {} };
    if (data instanceof FormData) {
      config.headers = {
        'Content-Type': 'multipart/form-data'
      };
    }
    config.headers = setTokenInHeaders(config.headers);
    return instance.patch(url, data, config);
  },

  delete (url) {
    const headers = setTokenInHeaders({});
    return instance.delete(url, { headers });
  }
};

export { requests, apiConstants };
