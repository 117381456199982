
const getters = {
  getAccessToken (state) {
    return state.accessToken;
  },

  getUser (state) {
    return state.user;
  },

  getUserId (state) {
    return state.user?.id || null;
  }
};
export default getters;
