<template>
  <div>
    <h5>{{ increasingTitle }}</h5>
    <q-tabs
      v-model="activeTabName"
      dense
      class="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="left"
    >
      <q-tab
        v-for="(tab, idx) in tabs"
        :key="idx"
        :name="tab.name"
        :label="tab.label"
      />
    </q-tabs>
    <div class="row q-mt-lg">
      <div
        class="col-12 col-lg-4"
        v-for="(chart, idx) in charts"
        :key="idx"
      >
        <line-chart
          :chartName="chart.name"
          :axisX="axisX"
          :axisY="axesY[chart.entitiesName]"
        />
        <stats-for-chart
          :stats="stats[chart.entitiesName]"
          :title="chart.statsTitle"
          class="q-mt-lg"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import LineChart from '@/components/Charts/LineChart.vue';
import StatsForChart from '@/components/Charts/StatsForChart.vue';

const props = defineProps({
  entitiesData: {
    type: Object,
    default: () => ({})
  }
});

const increasingTitle = computed(() => props.entitiesData?.title);

// tabs
const tabs = ref([
  {
    name: 'ByYears',
    label: 'По годам',
    key: 'year'
  },
  {
    name: 'ByMonths',
    label: 'По месяцам',
    key: 'month'
  },
  {
    name: 'ByWeeks',
    label: 'По неделям',
    key: 'week'
  }
]);

const activeTabName = ref('ByMonths');
const activeTab = computed(() => tabs.value.find((tab) => tab.name === activeTabName.value));

// charts
const charts = ref([
  {
    name: 'Пользователи',
    entitiesName: 'users',
    statsTitle: 'Новые пользователи'
  },
  {
    name: 'Проекты',
    entitiesName: 'projects',
    statsTitle: 'Новые проекты'
  },
  {
    name: 'Пространства',
    entitiesName: 'workspaces',
    statsTitle: 'Новые пространства'
  }
]);

const chartsData = computed(() => {
  return props.entitiesData?.periods?.[activeTab.value.key];
});

const axisX = computed(() => Object.values(chartsData.value).map((point) => point.title));

const axesY = computed(() => {
  const axes = {
    users: [],
    projects: [],
    workspaces: []
  };
  Object.values(chartsData.value).forEach((item) => {
    axes.users.push(item.userCounter);
    axes.projects.push(item.projectCounter);
    axes.workspaces.push(item.workspaceCounter);
  });
  return axes;
});

// stats
const stats = computed(() => {
  const stats = {
    users: {},
    projects: {},
    workspaces: {}
  };
  stats.users = props.entitiesData.userStat;
  stats.projects = props.entitiesData.projectStat;
  stats.workspaces = props.entitiesData.workspaceStat;
  return stats;
});
</script>

<style lang="scss" scoped>
h5 {
  margin-left: 15px;
}
</style>
