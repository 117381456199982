<template>
  <q-dialog v-model="isOpen">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ modalContent.title }}</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section>
        <div class="column q-col-gutter-md" style="height: 200px">
          <template v-for="(input, idx) in packageInputs" :key="idx">
            <!-- Text -->
            <q-input
              v-if="input.type === 'text'"
              v-model="v$[input.field].$model"
              :error="v$[input.field].$error"
              :error-message="getErrorMessage(input.field)"
              :label="input.label"
              class="col col-md-5"
              filled
            />
            <!-- Number -->
            <q-input
              v-if="input.type === 'number'"
              v-model.number="v$[input.field].$model"
              type="number"
              :error="v$[input.field].$error"
              :error-message="getErrorMessage(input.field)"
              :label="input.label"
              class="col col-md-6"
              filled
            />
            <!-- Checkbox -->
            <q-checkbox
              v-if="input.type === 'checkbox'"
              v-model="v$[input.field].$model"
              size="sm"
              class="col col-sm-5"
              :label="input.label"
            />
            <!-- Date -->
            <q-input
              v-if="input.type === 'date'"
              v-model="v$[input.field].$model"
              mask="date"
              :error="v$[input.field].$error"
              :label="input.label"
              class="col col-md-6"
              filled
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="v$[input.field].$model">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </template>
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn
          type="submit"
          color="primary"
          no-caps
          @click="submit(props.activeItem)"
          >{{ modalContent.action }}</q-btn
        >
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { apiConstants, requests } from '../../api';

const $q = useQuasar();
const loader = useLoader();

const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  },
  activeModalName: {
    type: String,
    default: ''
  },
  activeItem: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['close', 'upd-list']);

const isOpen = ref(false);

const modalContent = computed(() => {
  switch (props.activeModalName) {
    case 'editLicenses':
      return {
        title: 'Редактирование пакета',
        action: 'Сохранить'
      };
    case 'addLicenses':
      return {
        title: 'Создание пакета',
        action: 'Сохранить'
      };
    default:
      return {};
  }
});

const packageInputs = computed(() => {
  return [
    {
      label: 'Название компании',
      field: 'company',
      type: 'text'
    },
    {
      label: 'Количество пользователей',
      field: 'count_users',
      type: 'number'
    },
    {
      label: 'Email',
      field: 'email',
      type: 'text'
    },
    {
      label: 'Окончание поддержки',
      field: 'support_date',
      type: 'date'
    }
  ];
});

const LICENSES_FIELDS = {
  company: '',
  count_users: null,
  email: '',
  support_date: ''
};

const licensesFields = ref({
  ...LICENSES_FIELDS
});

const isValidTitle = (value) => value.match(/^[а-яА-ЯёЁa-zA-Z+0-9\s]+$/);
const rules = computed(() => {
  return {
    company: {
      required,
      isCyrillicOrLatin: helpers.withMessage(
        'The value must contain Cyrillic or Latin letters',
        isValidTitle
      )
    },
    count_users: {
      required
    },
    email: {
      required,
      maxLength: maxLength(129)
    },
    support_date: {
      required
    }
  };
});

const v$ = useVuelidate(rules, licensesFields, { $autoDirty: true });

watch(
  () => props.isOpenModal,
  async () => {
    isOpen.value = props.isOpenModal;
    v$.value.$reset();

    if (props.activeModalName === 'editLicenses') {
      licensesFields.value.company = props.activeItem.company;
      licensesFields.value.count_users = props.activeItem.count_users;
      licensesFields.value.email = props.activeItem.email;
      licensesFields.value.support_date = props.activeItem.support_date.split('T')[0];
    } else {
      licensesFields.value = { ...LICENSES_FIELDS };
    }
  }
);

// methods
const getErrorMessage = (field) => {
  return v$.value[field].$errors[0]?.$message;
};

const clearPromocodeFields = () => {
  licensesFields.value = { ...LICENSES_FIELDS };
};

const submit = async (item) => {
  loader.show();

  let endpoint = '';
  const itemId = item?.id;
  switch (props.activeModalName) {
    case 'addLicenses':
      endpoint = apiConstants.LICENSES.ADD;
      break;
    default:
      endpoint = apiConstants.LICENSES.CHANGE(itemId);
      break;
  }

  const requestParams = JSON.parse(JSON.stringify(licensesFields.value));
  requestParams.support_date = requestParams.support_date.replaceAll('/', '-');
  let response = {};
  let isErrorRequest = false;
  try {
    switch (props.activeModalName) {
      case 'editLicenses':
        response = await requests.put(endpoint, requestParams);
        break;
      default:
        response = await requests.post(endpoint, requestParams);
        break;
    }

    $q.notify({
      message: response.data.message,
      color: 'green'
    });

    v$.value.$reset();
    clearPromocodeFields();
    emit('upd-list');
  } catch (error) {
    isErrorRequest = true;
    $q.notify({
      message: error.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
    if (!isErrorRequest) {
      emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.select-promocode-type {
  height: fit-content !important;
}
</style>
