<template>
  <div class="full-width full-height relative-position">
    <q-card class="container">
      <q-table
        title="Проекты"
        class="projects__table"
        ref="tableRef"
        v-model:pagination="pagination"
        :rows-per-page-options="[pagination.rowsPerPage]"
        :loading="loading"
        :rows="projectList"
        :columns="columns"
        @request="onRequest"
        separator="cell"
      >
        <template #body-cell-author="item">
          <q-td
            class="orange-clickable-text"
            @click="userDetailModal.toggleModal('user', item.row.author)"
            >{{ item.row.author.name }}</q-td
          >
        </template>
      </q-table>
    </q-card>
    <user-detail-modal ref="userDetailModal" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { apiConstants, requests } from '../../api';
import UserDetailModal from '@/components/Modals/UserDetailModal.vue';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();
const userDetailModal = ref(null);

// tariff list
const tariffList = ref([]);

// pagination
const tableRef = ref();
const loading = ref(true);
const projectList = ref([]);
const columns = computed(() => {
  return [
    { align: 'left', name: 'id', field: 'id', label: '#' },
    { align: 'left', name: 'title', field: 'title', label: 'Название' },
    {
      align: 'left',
      name: 'cases',
      field: (item) => item.counters.cases,
      label: 'Кейсы'
    },
    {
      align: 'left',
      name: 'defects',
      field: (item) => item.counters.defects,
      label: 'Дефекты'
    },
    {
      align: 'left',
      name: 'milestone',
      field: (item) => item.counters.milestone,
      label: 'Майлстоуны'
    },
    {
      align: 'left',
      name: 'runs',
      field: (item) => item.counters.runs,
      label: 'Тест-раны'
    },
    {
      align: 'left',
      name: 'users',
      field: (item) => item.counters.users,
      label: 'Пользователи'
    },
    {
      align: 'left',
      name: 'author',
      field: (item) => item.author.name,
      label: 'Автор'
    }
  ];
});
const pagination = ref({
  page: 1,
  rowsPerPage: process.env.VUE_APP_TABLE_ROWS_PER_PAGE,
  rowsNumber: 0
});

const fetchProjectList = async (page) => {
  loading.value = true;

  let endpoint = apiConstants.PROJECTS.LIST;
  if (page) {
    endpoint += `?page=${page}`;
  }

  try {
    const { data: projects } = await requests.get(endpoint);
    projectList.value = projects.data;
    const { tariffs } = projects;
    tariffList.value = tariffs;

    const { meta } = projects;

    pagination.value.page = meta.current_page;
    pagination.value.rowsPerPage = meta.per_page;
    pagination.value.rowsNumber = meta.total;
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    console.log(`${e.response.status}: ${e.response.statusText}`);
  } finally {
    loading.value = false;
  }
};

const onRequest = (props) => {
  fetchProjectList(props.pagination.page);
};

onMounted(() => {
  tableRef.value.requestServerInteraction();
});
</script>

<style scoped lang="scss">
.projects {
  &__table {
    min-width: 90%;
  }

  &__title {
    font-weight: 500;
    font-size: 22px;
  }
}

.orange-clickable-text {
  color: orange;
  cursor: pointer;
}
</style>
