import { apiConstants, requests } from '../../api';

const actions = {
  async fetchProjectList ({ commit }) {
    try {
      const { data } = await requests.get(apiConstants.PROJECTS.LIST);

      commit('updateProjectList', data.data);
      commit('updateTariffList', data.tariffs);
    } catch (e) {
      console.log(`${e.response.status}: ${e.response.statusText}`);
    }
  },
  async editProjectTariff ({ commit }, payload) {
    try {
      const { data } = await requests.post(apiConstants.PROJECTS.EDIT_TARIFF(payload.projectId), payload.formData);

      commit('updateProjectItem', data.data);
    } catch (e) {
      console.log(`${e.response.status}: ${e.response.statusText}`);
    }
  }
};
export default actions;
