<template>
  <q-dialog
    v-model="isOpen"
    class="modal"
  >
    <q-card :class="{ 'w-auto': activeModalName === 'delete' }">
      <q-card-section class="row items-center">
        <div class="text-h6">{{ modalContent.title }}</div>
        <q-space />
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="emit('close')"
        />
      </q-card-section>
      <q-card-section v-if="isShowForm">
        <q-input
          v-model="v$.title.$model"
          :error="v$.title.$error"
          :error-message="getErrorMessage(v$['title'])"
          label="Название"
          class="col col-md-6 q-mb-sm"
          filled
        />
        <q-input
          v-model="v$.description.$model"
          :error="v$.description.$error"
          :error-message="getErrorMessage(v$['description'])"
          label="Описание"
          class="col col-md-6 q-mb-sm"
          type="textarea"
          filled
        />
        <q-checkbox
          v-model="activeStatus"
          label="Категория активна?"
        />
      </q-card-section>
      <q-card-actions>
        <q-btn
          color="blue-grey"
          no-caps
          @click="$emit('close')"
        >
          {{ modalContent.cancel }}
        </q-btn>
        <q-btn
          :color="activeModalName === 'delete' ? 'red' : 'primary'"
          no-caps
          @click="submit"
        >
          {{ modalContent.action }}
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { apiConstants, requests } from '@/api';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { getErrorMessage } from '@/utils/methods.js';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  },
  activeModalName: {
    type: String,
    default: ''
  },
  activeItem: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['close', 'upd-list', 'update:isOpenModal']);

const $q = useQuasar();
const loader = useLoader();

const isOpen = computed({
  get: () => props.isOpenModal,
  set: (value) => emit('update:isOpenModal', value)
});

const modalContent = computed(() => {
  switch (props.activeModalName) {
    case 'create':
      return {
        title: 'Создание категории',
        action: 'Сохранить',
        cancel: 'Отменить'
      };
    case 'edit':
      return {
        title: 'Редактирование категории',
        action: 'Сохранить',
        cancel: 'Отменить'
      };
    case 'delete':
      return {
        title: 'Удалить категорию?',
        action: 'Удалить',
        cancel: 'Отменить'
      };
    default:
      return {};
  }
});

const isShowForm = computed(() => props.activeModalName !== 'delete');

const formValue = ref({
  title: '',
  description: '',
  is_active: false
});

const activeStatus = ref(false);

watch(
  () => activeStatus.value,
  () => {
    formValue.value.is_active = activeStatus.value ? 1 : 0;
  }
);

// validations
const rules = computed(() => {
  return {
    title: {
      required
    },
    description: {
      required
    },
    is_active: {}
  };
});

const v$ = useVuelidate(rules, formValue, { $autoDirty: true });

watch(
  () => props.activeItem,
  () => {
    if (props.activeItem) {
      formValue.value.title = props.activeItem?.row?.title ? props.activeItem?.row?.title : '';
      formValue.value.description = props.activeItem?.row?.description ? props.activeItem.row.description : '';
      activeStatus.value = !!props.activeItem?.row?.is_active;
      v$.value.$reset();
    }
  });

const submit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) { return; };

  loader.show(500);
  try {
    let response;
    if (props.activeModalName === 'create') {
      const endpoint = apiConstants.BLOG.CATEGORIES.LIST;
      response = await requests.post(endpoint, formValue.value);
    } else if (props.activeModalName === 'edit') {
      const endpoint = apiConstants.BLOG.CATEGORIES.UPDATE(props.activeItem.row.id);
      response = await requests.put(endpoint, formValue.value);
    } else {
      const endpoint = apiConstants.BLOG.CATEGORIES.UPDATE(props.activeItem.row.id);
      response = await requests.delete(endpoint);
    }

    emit('close');
    emit('upd-list');
    $q.notify({
      message: response.data.message,
      color: 'green'
    });
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    $q.notify({
      message: e.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
  }
};

</script>

<style lang="scss">
.q-dialog {
  &__inner {
    .q-card {
      width: 100%;

      &.w-auto {
        width: auto !important;
      }
    }
  }
}
</style>
