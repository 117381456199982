<template>
  <div class="full-width full-height relative-position">
    <div class="q-card container q-pa-md">
      <template v-if="Object.keys(widgets).length">
        <entities-grow :entitiesData="entitiesIncreasingData" />

        <q-separator class="q-my-xl" />

        <completed-payments :completedPaymentsData="completedPaymentsData" />

        <q-separator class="q-my-xl" />

        <uncompleted-payments :uncompletedPaymentsData="uncompletedPaymentsData" />
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { apiConstants, requests } from '@/api';
import EntitiesGrow from '@/views/Dashboard/EntitiesGrow.vue';
import CompletedPayments from '@/views/Dashboard/CompletedPayments.vue';
import UncompletedPayments from '@/views/Dashboard/UncompletedPayments.vue';
import { useLoader } from '@/composables/useLoader.js';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();

const loader = useLoader();

const widgets = ref({});

// first widgets group
const entitiesIncreasingData = computed(() => widgets.value?.entity_increasing);

// second widgets group
const completedPaymentsData = computed(() => widgets.value?.paid_invoices);

// third widgets group
const uncompletedPaymentsData = computed(() => widgets.value?.unpaid_invoices);

// getting widgets
const getWidgets = async () => {
  const endpoint = apiConstants.DASHBOARD.LIST;
  loader.show(500);
  try {
    const { data: widgetsData } = await requests.get(endpoint);

    widgets.value = widgetsData.widgets;
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    console.log(`${e.response.status}: ${e.response.statusText}`);
  } finally {
    loader.hide();
  }
};

getWidgets();
</script>

<style lang="scss" scoped></style>
