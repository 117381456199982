<template>
  <div class="workspace-actions">
    <q-btn @click="openModal(true, 'freezing-start-date')">Принудительная заморозка проекта</q-btn>
    <br>
    <q-btn
      class="q-mt-lg"
      @click="openModal(true, 'trial-end-date')"
    >Изменить trial</q-btn>
    <br>
    <q-btn
      class="q-mt-lg"
      @click="openModal(true, 'discount')"
    >Установить скидку</q-btn>
    <br>
    <q-btn
      class="q-mt-lg"
      @click="openModal(true, 'delete')"
    >Удалить пространство (безвовзратно)</q-btn>

    <q-input
      filled
      v-model="changesStartDate"
      mask="date"
      label="Дата применения изменений"
      hint="Если не указана дата применения изменений - изменения вступят в силу сразу после оплаты"
      class="workspace-actions__datepicker"
    >
      <template v-slot:append>
        <q-icon
          name="event"
          class="cursor-pointer"
        >
          <q-popup-proxy
            cover
            transition-show="scale"
            transition-hide="scale"
          >
            <q-date v-model="changesStartDate">
              <div class="row items-center justify-end">
                <q-btn
                  v-close-popup
                  label="Close"
                  color="primary"
                  flat
                />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>

    <q-dialog v-model="isOpenModal">
      <q-card>
        <q-card-section>
          <template v-if="activeModalName === 'freezing-start-date'">
            <h5 class="q-ma-none q-mb-sm">Укажите дату заморозки</h5>
            <q-date
              v-model="frozenStartDate"
              mask="YYYY-MM-DD"
              minimal
            />
          </template>
          <template v-else-if="activeModalName === 'delete'">
            <h5 class="q-ma-none q-mb-sm">Удаление пространства</h5>
            <p class="q-mb-sm">Вы уверены что хотите удалить пространство?</p>
            <p class="q-mt-sm">После завершения операции пространство будет удалено безвозвратно.</p>
            <q-btn
              class="q-mr-lg"
              @click="deleteWorkspace"
            >Удалить</q-btn>
            <q-btn v-close-popup>Отмена</q-btn>
          </template>
          <template v-else-if="activeModalName === 'trial-end-date'">
            <h5 class="q-ma-none q-mb-sm">Укажите дату окончания trial</h5>
            <q-date
              v-model="trialEndDate"
              mask="YYYY-MM-DD"
              minimal
            />
          </template>
          <template v-else>
            <h5 class="q-ma-none q-mb-sm">Укажите скидку</h5>
            <q-input
              outlined
              v-model="discountValue"
              :dense="dense"
              :rules="[val => (val >= 0 && val <= 100) || 'Скидка должна находиться в пределах от 0 до 100']"
              type="number"
            />
          </template>
          <div v-if="activeModalName !== 'delete'" class="q-mt-lg">
            <q-btn
              class="q-mr-lg"
              @click="saveWorkspace"
            >Сохранить</q-btn>
            <q-btn v-close-popup>Отмена</q-btn>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { apiConstants, requests } from '../../api';

const emit = defineEmits([
  'update-list',
  'close',
  'delete'
]);

const props = defineProps({
  workspace: {
    type: Object,
    required: true
  }
});

const $q = useQuasar();
const loader = useLoader();

const frozenStartDate = ref('');
const trialEndDate = ref('');
const discountValue = ref(props.workspace.discount * 100);
const changesStartDate = ref('');

const isOpenModal = ref(false);
const activeModalName = ref('');

const openModal = (status, pickerName) => {
  isOpenModal.value = status;
  activeModalName.value = pickerName;
};

watch(() => isOpenModal.value, () => {
  if (!isOpenModal.value) {
    activeModalName.value = '';
    frozenStartDate.value = '';
    trialEndDate.value = '';
  };
});

const validate = () => {
  if ((activeModalName.value === 'freezing-start-date' && !frozenStartDate.value.length) || (activeModalName.value === 'trial-end-date' && !trialEndDate.value.length)) {
    $q.notify({
      message: 'Не указана дата',
      color: 'red'
    });
    return false;
  } else if (activeModalName.value === 'discount' && (discountValue.value < 0 || discountValue.value > 100)) {
    $q.notify({
      message: 'Скидка должна находиться в пределах от 0 до 100',
      color: 'red'
    });
    return false;
  }
  return true;
};

const deleteWorkspace = async () => {
  loader.show(500);
  try {
    const endpoint = apiConstants.WORKSPACES.INFO(props.workspace.id);
    const response = await requests.delete(endpoint);

    $q.notify({
      message: response.data.message,
      color: 'green'
    });
    emit('delete', props.workspace);
    emit('close');
  } catch (e) {
    $q.notify({
      message: e.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
  }
};

const saveWorkspace = async () => {
  if (validate()) {
    loader.show(500);

    try {
      let response;
      if (activeModalName.value === 'freezing-start-date') {
        const endpoint = apiConstants.WORKSPACES.UPDATE_FROZEN(props.workspace.id);
        response = await requests.patch(endpoint, { date: frozenStartDate.value });
      } else if (activeModalName.value === 'trial-end-date') {
        const endpoint = apiConstants.WORKSPACES.UPDATE_TRIAL(props.workspace.id);
        response = await requests.patch(endpoint, { date: trialEndDate.value });
      } else {
        const endpoint = apiConstants.WORKSPACES.UPDATE_DISCOUNT(props.workspace.id);
        response = await requests.patch(endpoint, { discount: discountValue.value });
      }

      emit('update-list', response.data.data);
      $q.notify({
        message: response.data.message,
        color: 'green'
      });
    } catch (e) {
      $q.notify({
        message: e.response.data.message,
        color: 'red'
      });
    } finally {
      loader.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.workspace-actions {
  max-width: 450px;

  &__datepicker {
    margin-top: 30px;
  }
}
</style>
