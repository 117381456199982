<template>
  <div class="full-width full-height relative-position">
    <q-card class="container">
      <q-table
        class="invoices__table"
        ref="tableRef"
        hide-pagination
        :loading="loading"
        :rows="invoicesList"
        :columns="columns"
        :pagination="pagination"
        @request="onRequest"
        separator="cell"
      >
        <template v-slot:body-cell="props">
          <q-td
              :class="props.col.name === 'payer' ? 'payer' : ''"
              :title="props.col.name === 'payer' ? props.value : ''"
              :props="props"
          >
            {{props.value}}
          </q-td>
        </template>
        <template #top>
          <div class="row justify-between items-center full-width">
            <div class="q-table__title">
              Счета
            </div>

            <div class="control row justify-end items-center no-wrap">
              <q-select class="col-4" v-model="pay.current" :options="pay.options" borderless label="Оплачено" />
              <q-input
                class="col-6 ml-4"
                dense debounce="300" color="primary" placeholder="Поиск по ID" v-model="search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
              <q-checkbox class="col-6 ml-4" dense v-model="hideDeleted" label="Не показывать удаленные" color="orange" />
            </div>
          </div>

        </template>
        <template #body-cell-actions="item">
          <q-td :style="{ background: item.row?.is_deleted ? '#ffebeb' : 'inherit' }" class="relative-position overflow-hidden">
            <q-btn
              size="xs"
              round
              icon="list"
              flat
              class="cursor-pointer"
              @click="activeContext = item.key"
            >
              <q-menu
                transition-show="jump-down"
                transition-hide="jump-up"
                :offset="[50, 10]"
              >
                <q-list style="min-width: 100px">
                  <q-item
                    :clickable="!item.row?.is_deleted"
                    :class="{ 'menu-disabled': item?.row?.is_deleted }"
                    v-close-popup
                    @click="toggleModal(true, 'markAsRead', item)"
                  >
                    <q-item-section>
                      Отметить оплаченным
                    </q-item-section>
                  </q-item>
                </q-list>
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="downloadBill(item.row.id, item.row.link)"
                  >
                    <q-item-section>
                        Скачать счет
                    </q-item-section>
                  </q-item>
                </q-list>
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="downloadAct(item.row.id, item.row.link)"
                  >
                    <q-item-section>
                      Скачать акт
                    </q-item-section>
                  </q-item>
                </q-list>
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'deleteItem', item)"
                  >
                    <q-item-section>
                      Удалить
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </q-card>
    <div class="row justify-center q-mt-md">
      <q-pagination
        v-model="currentPage"
        color="grey-8"
        :max="pagination.max"
        size="sm"
      />
    </div>
    <invoices-modal
      v-model="isOpenModal"
      :active-modal-name="activeModalName"
      :item-id="activeItem?.row?.id ?? -1"
      @close="toggleModal(false)"
      @upd-list="fetchInvoicesList"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from 'vue';
import { apiConstants, requests } from '../../api';
import InvoicesModal from '../../components/Modals/InvoicesModal.vue';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();

const search = ref('');
const hideDeleted = ref(false);
const pay = reactive({
  options: [
    {
      label: 'Все',
      value: -1
    },
    {
      label: 'Да',
      value: 1
    },
    {
      label: 'Нет',
      value: 0
    }
  ],
  current: {
    label: 'Все',
    value: -1
  }
});
/// Refs ///
const loading = ref(true);

const tableRef = ref();

const invoicesList = ref([]);

const columns = ref([
  { align: 'left', name: 'id', field: 'id', label: 'ID' },
  { align: 'left', name: 'workspace', field: 'workspace', label: 'Пространство' },
  { align: 'left', name: 'amount', field: 'amount', label: 'Количество' },
  { align: 'left', name: 'payer', field: 'payer', label: 'Плательщик', headerStyle: { width: '150px' } },
  { align: 'left', name: 'is_completed', field: item => item.is_completed ? 'Да' : 'Нет', label: 'Оплачен (да/нет)' },
  { align: 'left', name: 'date', field: 'date', label: 'Дата' },
  { align: 'left', name: 'actions' }
].map(item => {
  const currentStyles = item?.style ?? {};
  const styleCallback = item => {
    return item.is_deleted ? { ...currentStyles, background: '#ffebeb' } : currentStyles;
  };

  return { ...item, style: styleCallback };
}
));

const pagination = ref({
  sortBy: 'desc',
  descending: false,
  rowsPerPage: 50,
  max: 1
});
const currentPage = ref(1);

// Modals data
const activeItem = ref({});
const isOpenModal = ref(false);
const activeModalName = ref('');

/// Handlers ///
const fetchInvoicesList = async (page) => {
  loading.value = true;

  let endpoint = `${apiConstants.INVOICES.LIST}?showDeleted=${!hideDeleted.value}`;

  if (page) {
    endpoint += `&page=${page}`;
  }

  if (search.value) {
    endpoint += `&search=${search.value}`;
  }

  if (pay.current.value > -1) {
    endpoint += `&completed=${pay.current.value === -1 ? '' : Boolean(pay.current.value)}`;
  }

  try {
    const { data: invoicesResponse } = await requests.get(endpoint);
    invoicesList.value = invoicesResponse.data;
    pagination.value.rowsPerPage = invoicesResponse.meta.per_page;
    pagination.value.max = invoicesResponse.meta.last_page;
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const setActiveItem = (item) => {
  activeItem.value = item;
};

const download = (id, data, prefix = '') => {
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(data);
  anchor.download = `${prefix ? prefix + '-' : ''}${id}`;
  anchor.click();
};

const downloadBill = async (id, href) => {
  const { data } = await requests.get(href, { responseType: 'blob' });
  download(id, data, 'bill');
};

const downloadAct = async (id, href) => {
  const { data } = await requests.get(href + '_act', { responseType: 'blob' });
  download(id, data, 'act');
};

const toggleModal = (status, modalName = '', item = {}) => {
  isOpenModal.value = status;
  activeModalName.value = modalName;
  setActiveItem(item);
};

const onRequest = async (props) => {
  await fetchInvoicesList();
};

/// Watch ///
watch([currentPage, hideDeleted, pay, search], async () => {
  await fetchInvoicesList(currentPage.value);
});

watch([search, hideDeleted, pay], async ([search, hideDeleted, pay]) => {
  loading.value = true;

  if (!(search || hideDeleted || pay.current >= 0)) {
    await fetchInvoicesList(currentPage.value);
    return;
  }

  let endpoint = `${apiConstants.INVOICES.LIST}?showDeleted=${!hideDeleted}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (pay.current.value > -1) {
    endpoint += `&completed=${pay.current.value === -1 ? '' : Boolean(pay.current.value)}`;
  }

  try {
    const { data: invoicesResponse } = await requests.get(endpoint);
    invoicesList.value = invoicesResponse.data;
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
});

/// Hooks ///
onMounted(() => {
  tableRef.value.requestServerInteraction();
});
</script>

<style lang="scss" scoped>
.menu-link {
  text-decoration: none;
  color: inherit;
}

.menu-disabled {
  color: gray;
  cursor: not-allowed;
}

.payer {
  display: block;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 14px;
}
</style>
