<template>
  <div>
    <q-table
      class="reviews__table q-mt-lg"
      ref="tableRef"
      v-model:pagination="pagination"
      :rows-per-page-options="[pagination.rowsPerPage]"
      :loading="loading"
      :rows="list"
      :columns="columns"
      separator="cell"
      @request="onRequest"
    >
      <template #top>
        <div class="row justify-between items-center full-width">
          <div class="q-table__title">
            Отзывы
          </div>
          <q-btn @click="toggleModal(true, 'create')">Добавить отзыв</q-btn>
        </div>
      </template>
      <template #body-cell-actions="item">
        <q-td class="relative-position overflow-hidden">
          <q-btn
            size="xs"
            round
            icon="list"
            flat
            class="cursor-pointer"
            @click="activeContext = item.key"
          >
            <q-menu
              transition-show="jump-down"
              transition-hide="jump-up"
              :offset="[50, 10]"
            >
              <q-list style="min-width: 100px">
                <q-item
                  clickable
                  @click="toggleModal(true, 'edit', item)"
                  v-close-popup
                >
                  <q-item-section>
                    Редактировать
                  </q-item-section>
                </q-item>
              </q-list>
              <q-list style="min-width: 100px">
                <q-item
                  clickable
                  v-close-popup
                  @click="toggleModal(true, 'delete', item)"
                >
                  <q-item-section>
                    Удалить
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <review-modal
      v-model="isOpenModal"
      :activeModalName="activeModalName"
      :activeItem="activeItem"
      :categoriesList="categoriesList"
      @close="toggleModal(false)"
      @upd-list="fetchReviewsList"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { apiConstants, requests } from '@/api';
import ReviewModal from './ReviewModal.vue';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();

/// Refs ///
const loading = ref(true);

const tableRef = ref();

const list = ref([]);
const categoriesList = ref([]);

const columns = ref([
  { align: 'left', name: 'id', field: 'id', label: 'ID' },
  { align: 'left', name: 'title', field: 'title', label: 'Название' },
  { align: 'left', name: 'company', field: 'company', label: 'Организация' },
  { align: 'left', name: 'person_name', field: 'person_name', label: 'Пользователь' },
  { align: 'left', name: 'person_position', field: 'person_position', label: 'Должность' },
  { align: 'left', name: 'description', field: 'description', label: 'Описание' },
  { align: 'left', name: 'text', field: 'text', label: 'Текс' },
  { align: 'left', name: 'actions' }
]);

// Modals data
const activeItem = ref(null);
const isOpenModal = ref(false);
const activeModalName = ref('');

const pagination = ref({
  page: 1,
  rowsPerPage: process.env.VUE_APP_TABLE_ROWS_PER_PAGE,
  rowsNumber: 0
});

/// Handlers ///
const fetchReviewsList = async (page) => {
  loading.value = true;

  let endpoint = apiConstants.REVIEWS.LIST;
  if (page) {
    endpoint += `?page=${page}`;
  }

  try {
    const { data: reviews } = await requests.get(endpoint);
    list.value = reviews.data;
    const { meta } = reviews;

    pagination.value.page = meta.current_page;
    pagination.value.rowsPerPage = meta.per_page;
    pagination.value.rowsNumber = meta.total;
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const setActiveItem = (item) => {
  activeItem.value = item;
};

const toggleModal = (status, modalName = '', item = {}) => {
  isOpenModal.value = status;
  activeModalName.value = modalName;
  setActiveItem(item);
};

const onRequest = async (props) => {
  await fetchReviewsList(props.pagination.page);
};

onMounted(() => {
  tableRef.value.requestServerInteraction();
});
</script>

<style lang="scss" scoped></style>
