<template>
  <q-dialog v-model="isOpen">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ modalContent.title }}</div>
        <q-space />
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>
      <q-card-section>
        <div class="modal__content">
          {{ modalContent.text }}
        </div>
      </q-card-section>
        <q-card-actions v-if="activeModalName !== 'comment'">
          <q-btn
            color="blue-grey"
            no-caps
            @click="emit('close')"
          >
            {{ modalContent.cancel }}
          </q-btn>
          <q-btn
            :color="buttonColor"
            no-caps
            @click="submit"
          >
            {{ modalContent.action }}
          </q-btn>
        </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { apiConstants, requests } from '../../api';

const $q = useQuasar();
const loader = useLoader();

const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  },
  activeModalName: {
    type: String,
    default: ''
  },
  activeItem: {
    type: Object,
    default: () => ({})
  },
  itemId: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['close', 'upd-list']);

const isOpen = ref(false);

const buttonColor = computed(() => {
  return props.activeModalName === 'deleteItem' ? 'red' : 'primary';
});

const modalContent = computed(() => {
  switch (props.activeModalName) {
    case 'markAsRead':
      return {
        title: 'Отметить обращение обработанным?',
        text: 'Вы действительно хотите отметить данное обращение как обработанное?',
        action: 'Отметить обработанным',
        cancel: 'Отменить'
      };
    case 'markAsUnread':
      return {
        title: 'Вернуть обращение в работу?',
        text: 'Вы действительно хотите вернуть данное обращение в работу?',
        action: 'Вернуть в работу',
        cancel: 'Отменить'
      };
    case 'deleteItem':
      return {
        title: 'Удаление обращение',
        text: 'Вы действительно хотите удалить данное обращение?\nДанное действие невозможно отменить.',
        action: 'Удалить обращение',
        cancel: 'Отменить'
      };
    case 'comment':
      return {
        title: 'Комментарий',
        text: props.activeItem?.comment || '',
        action: '',
        cancel: ''
      };
    default:
      return {};
  }
});

watch(
  () => props.isOpenModal,
  () => {
    isOpen.value = props.isOpenModal;
  }
);

watch(isOpen, () => {
  if (!isOpen.value) {
    emit('close');
  }
});

const submit = async () => {
  loader.show();

  let endpoint = '';

  let response = {};

  try {
    switch (props.activeModalName) {
      case 'deleteItem':
        endpoint = apiConstants.FEEDBACK.DELETE(props.itemId);
        response = await requests.delete(endpoint);
        break;
      case 'markAsUnread':
        endpoint = apiConstants.FEEDBACK.UNCOMPLETE(props.itemId);
        response = await requests.patch(endpoint);
        break;
      default:
        endpoint = apiConstants.FEEDBACK.COMPLETE(props.itemId);
        response = await requests.patch(endpoint);
        break;
    }

    $q.notify({
      message: response.data.message,
      color: 'green'
    });

    emit('upd-list');
    loader.hide();
    emit('close');
  } catch (error) {
    $q.notify({
      message: error.response.data.message,
      color: 'red'
    });
    loader.hide();
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__content {
    white-space: pre-wrap;
  }
}
</style>
