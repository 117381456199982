<template>
  <q-card-section class="row items-center q-pb-none">
    <div class="text-h6">Создание пользователя</div>
    <q-space />
    <q-btn
      icon="close"
      flat
      round
      dense
      v-close-popup
    />
  </q-card-section>
  <q-card-section>
    <q-input
      v-model="v$.name.$model"
      :error="v$.name.$error"
      :error-message="getErrorMessage(v$['name'])"
      label="Логин"
      class="col col-md-6 q-mb-sm"
      filled
    />
    <q-input
      v-model="v$.email.$model"
      :error="v$.email.$error"
      :error-message="getErrorMessage(v$['email'])"
      label="Email"
      class="col col-md-6 q-mb-sm"
      filled
    />
    <q-checkbox v-model="isNeedCreateWorkspace" label="Создать рабочее пространство?"/>
    <template v-if="isNeedCreateWorkspace">
      <q-input
        v-model="v$.workspace_name.$model"
        :error="v$.workspace_name.$error"
        :error-message="getErrorMessage(v$['workspace_name'])"
        label="Название пространства"
        class="col col-md-6 q-mb-sm q-mt-lg"
        filled
      />
      <q-input
        v-model="v$.workspace_alias.$model"
        :error="v$.workspace_alias.$error"
        :error-message="getErrorMessage(v$['workspace_alias'])"
        label="Alias"
        class="col col-md-6"
        filled
      />
      <q-checkbox v-model="formValue.create_project" label="Создать тестовый проект?"/>
    </template>
  </q-card-section>
  <q-card-actions>
    <q-btn
      type="submit"
      color="primary"
      no-caps
      @click="createUser"
    >
      Создать пользователя
    </q-btn>
    <q-btn
      type="submit"
      color="primary"
      no-caps
      v-close-popup
    >
      Отмена
    </q-btn>
  </q-card-actions>
</template>

<script setup>
import { ref, computed, defineEmits } from 'vue';
import { apiConstants, requests } from '../../api';
import { useLoader } from '@/composables/useLoader.js';
import { useQuasar } from 'quasar';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, maxLength, alphaNum, requiredUnless, helpers } from '@vuelidate/validators';
import { isLowerCase, getErrorMessage, isUnique } from '@/utils/methods.js';

const emit = defineEmits([
  'close'
]);

const $q = useQuasar();
const loader = useLoader();

const formValue = ref({
  name: '',
  email: '',
  workspace_name: '',
  workspace_alias: '',
  create_project: false
});

const formValueBuffer = ref({});

const isNeedCreateWorkspace = ref(false);

// validations
const validLogin = (value) => /^[A-Za-z]/.test(value) && /^[A-Za-z0-9._-]{5,20}$/.test(value);

const uniqueErrFromApi = ref([]);

const rules = computed(() => {
  return {
    name: {
      required,
      validLogin: helpers.withMessage('Поле логина должно состоять из латинских символов, цифр, символов ".", "_", "-"', validLogin),
      mustBeUnique: helpers.withMessage('This login already exists', isUnique('name', formValue.value, formValueBuffer.value, uniqueErrFromApi.value))
    },
    email: {
      required,
      email,
      mustBeUnique: helpers.withMessage('This email already exists', isUnique('email', formValue.value, formValueBuffer.value, uniqueErrFromApi.value))
    },
    workspace_name: {
      required: requiredUnless(() => {
        return !isNeedCreateWorkspace.value;
      }),
      minLength: minLength(4),
      maxLength: maxLength(20)
    },
    workspace_alias: {
      required: requiredUnless(() => {
        return !isNeedCreateWorkspace.value;
      }),
      minLength: minLength(4),
      maxLength: maxLength(10),
      alphaNum,
      isLowerCase,
      mustBeUnique: helpers.withMessage('This workspace alias already exists', isUnique('workspace_alias', formValue.value, formValueBuffer.value, uniqueErrFromApi.value))
    }
  };
});

const v$ = useVuelidate(rules, formValue, { $autoDirty: true });

const createUser = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) { return; };

  const endpoint = apiConstants.USERS.LIST;
  loader.show(500);

  try {
    const response = await requests.post(endpoint, formValue.value);

    emit('close');
    $q.notify({
      message: response.data.message,
      color: 'green'
    });
  } catch (e) {
    $q.notify({
      message: e.response.data.message,
      color: 'red'
    });
    if (e.response.request.status === 422) {
      Object.keys(e.response.data.errors).forEach((errorKey) => uniqueErrFromApi.value.push(errorKey));
      formValueBuffer.value = { ...formValue.value };
    }
  } finally {
    loader.hide();
  }
};
</script>

<style lang="scss" scoped>

</style>
