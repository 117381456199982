import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import { getToken } from '../utils/token';
import Login from '../views/Auth/Login';
import HomeLayout from '@/views/Layouts/HomeLayout';
import AuthLayout from '@/views/Layouts/AuthLayout';
import ProjectList from '@/views/Project/ProjectList';
import UsersList from '@/views/Users/UsersList';
import TariffsList from '@/views/Tariffs/TariffsList';
import PackagesList from '@/views/Packages/PackagesList';
import WorkspacesList from '@/views/Workspaces/WorkspacesList';
import LicensesList from '@/views/Licenses/LicensesList';
import PromoCodes from '@/views/PromoCodes/PromoCodes';
import InvoicesList from '@/views/Invoices/InvoicesList';
import FeedbackList from '@/views/Feedback/FeedbackList';
import Dashboard from '@/views/Dashboard/Dashboard';

// content
import Blog from '@/views/Content/Blog/Blog';
import Reviews from '@/views/Content/Reviews/Reviews';

const routes = [
  {
    path: '/',
    component: HomeLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
          isRequireAuth: true,
          title: 'Home'
        }
      },
      {
        path: 'projects',
        name: 'ProjectList',
        component: ProjectList,
        meta: {
          isRequireAuth: true,
          title: 'ProjectList'
        }
      },
      {
        path: 'users',
        name: 'UsersList',
        component: UsersList,
        meta: {
          isRequireAuth: true,
          title: 'UsersList'
        }
      },
      {
        path: 'packages',
        name: 'PackagesList',
        component: PackagesList,
        meta: {
          isRequireAuth: true,
          title: 'PackagesList'
        }
      },
      {
        path: 'workspaces',
        name: 'WorkspacesList',
        component: WorkspacesList,
        meta: {
          isRequireAuth: true,
          title: 'WorkspacesList'
        }
      },
      {
        path: 'promocodes',
        name: 'PromoCodes',
        component: PromoCodes,
        meta: {
          isRequireAuth: true,
          title: 'PromoCodes'
        }
      },
      {
        path: 'invoices',
        name: 'InvoicesList',
        component: InvoicesList,
        meta: {
          isRequireAuth: true,
          title: 'InvoicesList'
        }
      },
      {
        path: 'licenses',
        name: 'LicensesList',
        component: LicensesList,
        meta: {
          isRequireAuth: true,
          title: 'LicensesList'
        }
      },
      {
        path: 'tariffs',
        name: 'TariffsList',
        component: TariffsList,
        meta: {
          isRequireAuth: true,
          title: 'TariffsList'
        }
      },
      {
        path: 'feedback',
        name: 'FeedbackList',
        component: FeedbackList,
        meta: {
          isRequireAuth: true,
          title: 'FeedbackList'
        }
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          isRequireAuth: true,
          title: 'Dashboard'
        }
      },
      {
        path: 'blog',
        name: 'Blog',
        component: Blog,
        meta: {
          isRequireAuth: true,
          title: 'Blog'
        }
      },
      {
        path: 'reviews',
        name: 'Reviews',
        component: Reviews,
        meta: {
          isRequireAuth: true,
          title: 'Reviews'
        }
      }
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          isRequireAuth: false,
          title: 'Login'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(({ matched }, _, next) => {
  const isRequireAuth = matched.some((record) => record.meta?.isRequireAuth);
  const logout = matched.some((record) => record.meta?.logout);
  const isToken = getToken();
  if (logout) {
    return next();
  }
  if (isRequireAuth && !isToken) {
    return next({ name: 'Login' });
  }

  return next();
});

export default router;
