const mutation = {
  updateToken (state, payload) {
    state.accessToken = payload;
  },
  updateUser (state, payload) {
    state.user = payload;
  }
};

export default mutation;
