<template>
  <div class="full-width full-height relative-position">
    <q-card class="container">
      <q-table
        class="promocodes__table"
        ref="tableRef"
        :loading="loading"
        :rows="promocodes"
        :columns="columns"
        :pagination="pagination"
        @request="onRequest"
        hide-pagination
        separator="cell"
      >
        <template #top>
          <div class="row justify-between items-center full-width">
            <div class="q-table__title">
              Промокоды
            </div>
            <div>
              <q-btn @click="toggleModal(true, 'addPromocode')">Добавить промокод</q-btn>
            </div>
          </div>
        </template>
        <template #body-cell-actions="item">
          <q-td :style="{ background: item.row?.is_active ? 'inherit' : '#ffebeb' }" class="relative-position overflow-hidden">
            <q-btn
              size="xs"
              round
              icon="list"
              flat
              class="cursor-pointer"
              @click="activeContext = item.key"
            >
              <q-menu
                transition-show="jump-down"
                transition-hide="jump-up"
                :offset="[50, 10]"
              >
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'editPromocode', item.row)"
                  >
                    <q-item-section>
                      Редактировать
                    </q-item-section>
                  </q-item>
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'deletePromocode', item.row)"
                  >
                    <q-item-section>
                      Удалить
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </q-card>
    <div class="row justify-center q-mt-md">
      <q-pagination
        v-model="currentPage"
        color="grey-8"
        :max="pagination.max"
        size="sm"
      />
    </div>
    <q-dialog v-model="isOpenModal">
      <q-card>
        <template v-if="activeModalName === 'deletePromocode'">
          <q-card-section class="row items-center q-pb-none">
            <div class="text-h6">Удалить промокод?</div>
            <q-space />
            <q-btn
              icon="close"
              flat
              round
              dense
              v-close-popup
            />
          </q-card-section>
          <q-card-actions>
            <q-btn
              type="submit"
              color="primary"
              no-caps
              @click="deleteSubmit(activeItem)"
            >Удалить</q-btn>
          </q-card-actions>
        </template>
        <template v-else>
          <create-user-form @close="toggleModal" />
        </template>
      </q-card>
    </q-dialog>
    <form-promocode-modal
      :isOpenModal="isOpenModal && activeModalName !== 'deletePromocode'"
      :activeModalName="activeModalName"
      :activeItem="activeItem"
      @hide="toggleModal(false)"
      @close="toggleModal(false)"
      @upd-list="fetchPromocodeList"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from 'vue';
import { apiConstants, requests } from '../../api';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import FormPromocodeModal from '@/components/Modals/FormPromocodeModal';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();

const $q = useQuasar();
const loader = useLoader();

/// Refs ///
const loading = ref(true);

const tableRef = ref();
const pagination = ref({
  sortBy: 'desc',
  descending: false,
  rowsPerPage: 50,
  max: 1
});
const currentPage = ref(1);

const promocodes = ref([]);

const columns = ref([
  { align: 'left', name: 'id', headerStyle: 'width: 50px', field: 'id', label: 'ID' },
  { align: 'left', name: 'code', field: 'code', label: 'Код' },
  { align: 'left', name: 'type', field: 'type', label: 'Тип' },
  { align: 'left', headerStyle: 'width: 50px', name: 'actions' }
].map(item => {
  const currentStyles = item?.style ?? {};
  const styleCallback = item => {
    return item.is_active ? currentStyles : { ...currentStyles, background: '#ffebeb' };
  };

  return { ...item, style: styleCallback };
}
));

// Modals data
const activeItem = ref({});
const subscriptionConfig = ref({});
const isOpenModal = ref(false);
const activeModalName = ref('');

const setActiveItem = (item) => {
  activeItem.value = item;
};

const toggleModal = (status, modalName = '', item = {}) => {
  isOpenModal.value = status;
  activeModalName.value = modalName;
  setActiveItem(item);
};

/// Handlers ///
const fetchPromocodeList = async (page) => {
  loading.value = true;

  let endpoint = apiConstants.PROMOCODES.LIST;
  if (page) {
    endpoint += `?page=${page}`;
  }

  try {
    const { data: promocodeResponse } = await requests.get(endpoint);
    promocodes.value = promocodeResponse.data;
    subscriptionConfig.value = promocodeResponse.subscriptionConfig;
    // Meta
    pagination.value.rowsPerPage = promocodeResponse.meta.per_page;
    pagination.value.max = promocodeResponse.meta.last_page;
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const deleteListItem = (item) => {
  promocodes.value = promocodes.value.filter(p => p.id !== item.id);
};

async function deleteSubmit (item) {
  const endpoint = apiConstants.PROMOCODES.DELETE(item.id);

  loader.show(500);
  let response = {};
  let hasError = false;
  try {
    response = await requests.delete(endpoint);
    $q.notify({
      message: response.data.message,
      color: 'green'
    });
    deleteListItem(item);
  } catch (error) {
    hasError = true;
    $q.notify({
      message: error.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
    if (!hasError) {
      toggleModal(false);
    }
  }
};

/// Watch ///
watch(currentPage, async () => {
  await fetchPromocodeList(currentPage.value);
});

const onRequest = async (props) => {
  await fetchPromocodeList();
};

/// Hooks ///
onMounted(() => {
  tableRef.value.requestServerInteraction();
});
</script>

<style lang="scss" scoped>

.promocodes__table {
  height: calc(100vh - 100px);

  &:deep(.q-table__top,
    .q-table__bottom,
    thead tr:first-child th) {
    background-color: #fff;
  }

  &:deep(thead tr th) {
    position: sticky;
    background-color: #fff;
    z-index: 1;
  }

  &:deep(thead tr:first-child th) {
    top: 0;
  }

  &:deep(&.q-table--loading thead tr:last-child th) {
    top: 48px
  }

  &:deep(tbody) {
    scroll-margin-top: 48px
  }
}
</style>
