<template>
  <q-dialog v-model="isOpen">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ modalContent.title }}</div>
        <q-space />
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>
      <q-card-section v-if="props.activeModalName === 'showTariffDetail'">
        <!-- TODO: доделать, когда эндроинт будет отвечать. Запрос производится в вотчере -->
        Модалка требует доработки - эндпоинт не отвечает
      </q-card-section>
      <q-card-section v-if="props.activeModalName === 'createTariff' || props.activeModalName === 'editTariff'">
        <div class="row q-col-gutter-md">
          <q-input
            v-for="(input, idx) in tariffInputs"
            :key="idx"
            v-model="v$[input.field].$model"
            :error="v$[input.field].$error"
            :error-message="getErrorMessage(input.field)"
            :label="input.label"
            class="col col-md-6"
            filled
          />
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn
          type="submit"
          color="primary"
          no-caps
          @click="submit(props.activeItem)"
        >{{ modalContent.action }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { useVuelidate } from '@vuelidate/core';
import { required, numeric, alpha, helpers } from '@vuelidate/validators';
import { apiConstants, requests } from '../../api';

const $q = useQuasar();
const loader = useLoader();

const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  },
  activeModalName: {
    type: String,
    default: ''
  },
  activeItem: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['close', 'upd-list']);

const isOpen = ref(false);

const modalContent = computed(() => {
  switch (props.activeModalName) {
    case 'showTariffDetail':
      return {
        title: 'Детали тарифа',
        action: 'Закрыть'
      };
    case 'editTariff':
      return {
        title: 'Редактирование тарифа',
        action: 'Сохранить'
      };
    case 'deleteTariff':
      return {
        title: 'Удалить тариф?',
        action: 'Удалить'
      };
    case 'createTariff':
      return {
        title: 'Создание тарифа',
        action: 'Сохранить'
      };
    default:
      return {};
  }
});

const tariffDetail = ref({});

const tariffInputs = computed(() => {
  return [
    {
      label: 'Title',
      field: 'title'
    },
    {
      label: 'Alias',
      field: 'alias'
    },
    {
      label: 'Price',
      field: 'price'
    },
    {
      label: 'Storage size',
      field: 'storage_size'
    },
    {
      label: 'User limit',
      field: 'user_limit'
    },
    {
      label: 'Case limit',
      field: 'case_limit'
    },
    {
      label: 'Run limit',
      field: 'run_limit'
    },
    {
      label: 'Plan limit',
      field: 'plan_limit'
    },
    {
      label: 'Defect limit',
      field: 'defect_limit'
    },
    {
      label: 'Milestone limit',
      field: 'milestone_limit'
    }
  ];
});

const TARIFF_FIELDS = {
  title: '',
  alias: '',
  price: null,
  storage_size: null,
  user_limit: null,
  case_limit: null,
  run_limit: null,
  plan_limit: null,
  defect_limit: null,
  milestone_limit: null
};

const tariffFields = ref({
  ...TARIFF_FIELDS
});

// validations
const isCyrillicOrLatin = (value) => value.match(/^[а-яА-ЯёЁa-zA-Z]+$/);

const rules = computed(() => {
  return {
    title: {
      required,
      isCyrillicOrLatin: helpers.withMessage('The value must contain Cyrillic or Latin letters', isCyrillicOrLatin)
    },
    alias: {
      required,
      alpha: helpers.withMessage('The value is not Latin alphabetical', alpha)
    },
    price: {
      required,
      numeric
    },
    storage_size: {
      required,
      numeric
    },
    user_limit: {
      required,
      numeric
    },
    case_limit: {
      required,
      numeric
    },
    run_limit: {
      required,
      numeric
    },
    plan_limit: {
      required,
      numeric
    },
    defect_limit: {
      required,
      numeric
    },
    milestone_limit: {
      required,
      numeric
    }
  };
});

const v$ = useVuelidate(rules, tariffFields, { $autoDirty: true });

watch(
  () => props.isOpenModal,
  async () => {
    isOpen.value = props.isOpenModal;
    v$.value.$reset();

    switch (props.activeModalName) {
      case 'showTariffDetail':
        tariffDetail.value = await requests.get(apiConstants.TARIFFS.TARIFF(props.activeItem.value.id));
        break;
      case 'editTariff':
        tariffFields.value = { ...props.activeItem.row, ...props.activeItem.row.properties };
        break;
    }
  }
);

// methods
const getErrorMessage = (field) => {
  return v$.value[field].$errors[0]?.$message;
};

const clearTariffFields = () => {
  tariffFields.value = { ...TARIFF_FIELDS };
};

const submit = async (item) => {
  if (props.activeModalName !== 'deleteTariff') {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) { return; };
  }

  loader.show();

  let endpoint = '';
  const itemId = item?.row?.id;
  switch (props.activeModalName) {
    case 'createTariff':
      endpoint = apiConstants.TARIFFS.LIST;
      break;
    default:
      endpoint = apiConstants.TARIFFS.TARIFF(itemId);
      break;
  }

  let response = {};
  let isErrorRequest = false;
  try {
    switch (props.activeModalName) {
      case 'editTariff':
        response = await requests.put(endpoint, tariffFields.value);
        break;
      case 'deleteTariff':
        response = await requests.delete(endpoint);
        break;
      default:
        response = await requests.post(endpoint, tariffFields.value);
        break;
    }

    $q.notify({
      message: response.data.message,
      color: 'green'
    });

    v$.value.$reset();
    clearTariffFields();
    emit('upd-list');
  } catch (error) {
    isErrorRequest = true;
    $q.notify({
      message: error.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
    if (!isErrorRequest) {
      emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
