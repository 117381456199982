<template>
  <div class="workspace-information">
    <q-input
      v-model="v$.title.$model"
      :error="v$.title.$error"
      :error-message="getErrorMessage(v$['title'])"
      label="Название проекта"
      class="col col-md-6 q-mb-sm"
      filled
    />
    <q-input
      v-model="v$.alias.$model"
      :error="v$.alias.$error"
      :error-message="getErrorMessage(v$['alias'])"
      label="Alias"
      class="col col-md-6 q-mb-sm"
      filled
    />
    <q-input
      v-model="v$.info.$model"
      :error="v$.info.$error"
      :error-message="getErrorMessage(v$['info'])"
      label="Описание проекта"
      class="col col-md-6 q-mb-sm"
      filled
      type="textarea"
    />
    <div class="workspace-information__actions">
      <q-btn
        type="submit"
        color="primary"
        no-caps
        @click="updateWorkspace"
      >
        Cохранить
      </q-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onBeforeMount } from 'vue';
import { apiConstants, requests } from '../../api';
import { useLoader } from '@/composables/useLoader.js';
import { useQuasar } from 'quasar';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength, alphaNum, helpers } from '@vuelidate/validators';
import { isLowerCase, getErrorMessage, isUnique } from '@/utils/methods.js';

const emit = defineEmits([
  'update-list'
]);

const props = defineProps({
  workspace: {
    type: Object,
    required: true
  }
});

const $q = useQuasar();
const loader = useLoader();

const formValue = ref({
  title: '',
  alias: '',
  info: ''
});

const formValueBuffer = ref({});

// validations
const uniqueErrFromApi = ref([]);

const rules = computed(() => {
  return {
    title: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(20)
    },
    alias: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(10),
      alphaNum,
      isLowerCase,
      mustBeUnique: helpers.withMessage('This alias already exists', isUnique('alias', formValue.value, formValueBuffer.value, uniqueErrFromApi.value))
    },
    info: {
      maxLength: maxLength(255)
    }
  };
});

const v$ = useVuelidate(rules, formValue, { $autoDirty: true });

onBeforeMount(() => {
  formValue.value.title = props.workspace.title;
  formValue.value.alias = props.workspace.alias;
  formValue.value.info = props.workspace.info;
  v$.value.$reset();
});

// methods
const updateWorkspace = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) { return; };

  const endpoint = apiConstants.WORKSPACES.INFO(props.workspace.id);
  loader.show(500);

  try {
    const response = await requests.put(endpoint, formValue.value);

    emit('update-list', response.data.data);
    $q.notify({
      message: response.data.message,
      color: 'green'
    });
  } catch (e) {
    $q.notify({
      message: e.response.data.message,
      color: 'red'
    });
    if (e.response.request.status === 422) {
      Object.keys(e.response.data.errors).forEach((errorKey) => uniqueErrFromApi.value.push(errorKey));
      formValueBuffer.value = { ...formValue.value };
    }
  } finally {
    loader.hide();
  }
};
</script>

<style lang="scss" scoped>
.workspace-information {
  max-width: 500px;
  margin: auto;

  &__actions {
    display: flex;
    gap: 28px;
  }
}
</style>
