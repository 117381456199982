import script from "./WorkspaceChangesTab.vue?vue&type=script&setup=true&lang=js"
export * from "./WorkspaceChangesTab.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTd,QBtn,QDialog,QCard,QCardSection,QCardActions});
