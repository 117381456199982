<template>
  <q-dialog v-model="isOpen">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ modalContent.title }}</div>
        <q-space />
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>
      <q-card-section>
        <div class="column q-col-gutter-md" style="height: 250px">
          <template v-for="(input, idx) in packageInputs" :key="idx">
            <!-- Text -->
            <q-input
              v-if="input.type === 'text'"
              v-model="v$[input.field].$model"
              :error="v$[input.field].$error"
              :error-message="getErrorMessage(input.field)"
              :label="input.label"
              class="col col-md-4"
              filled
            />
            <!-- Select -->
            <q-select
              v-if="input.type === 'select'"
              filled
              v-model="v$[input.field].$model"
              :error="v$[input.field].$error"
              :error-message="getErrorMessage(input.field)"
              class="col col-sm-4"
              :options="input?.options ?? []"
              :label="input.label"
              :disable="activeModalName === 'editPromocode'"
            />
            <!-- Checkbox -->
            <q-checkbox
              v-if="input.type === 'checkbox'"
              v-model="v$[input.field].$model"
              size="sm"
              class="col col-sm-4"
              :label="input.label"
            />
            <!-- Textarea -->
            <q-input
              v-if="input.type === 'textarea'"
              v-model="v$[input.field].$model"
              type="textarea"
              :error="v$[input.field].$error"
              :error-message="getErrorMessage(input.field)"
              :label="input.label"
              class="col col-sm-6"
              filled
            />
          </template>
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn
          type="submit"
          color="primary"
          no-caps
          @click="submit(props.activeItem)"
        >{{ modalContent.action }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { apiConstants, requests } from '../../api';

const $q = useQuasar();
const loader = useLoader();

const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  },
  activeModalName: {
    type: String,
    default: ''
  },
  activeItem: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['close', 'upd-list']);

const isOpen = ref(false);

const modalContent = computed(() => {
  switch (props.activeModalName) {
    case 'editPromocode':
      return {
        title: 'Редактирование пакета',
        action: 'Сохранить'
      };
    case 'addPromocode':
      return {
        title: 'Создание пакета',
        action: 'Сохранить'
      };
    default:
      return {};
  }
});

const SERVICE_TYPES = [
  {
    label: 'trial_1_m',
    value: 'trial_1_m'
  },
  {
    label: 'trial_2_m',
    value: 'trial_2_m'
  },
  {
    label: 'trial_3_m',
    value: 'trial_3_m'
  },
  {
    label: 'trial_6_m',
    value: 'trial_6_m'
  },
  {
    label: 'trial_12_m',
    value: 'trial_12_m'
  },
  {
    label: 'sale5',
    value: 'sale5'
  },
  {
    label: 'sale10',
    value: 'sale10'
  },
  {
    label: 'sale15',
    value: 'sale15'
  },
  {
    label: 'sale20',
    value: 'sale20'
  },
  {
    label: 'sale25',
    value: 'sale25'
  },
  {
    label: 'sale30',
    value: 'sale30'
  },
  {
    label: 'sale35',
    value: 'sale35'
  },
  {
    label: 'sale40',
    value: 'sale40'
  },
  {
    label: 'sale45',
    value: 'sale45'
  },
  {
    label: 'sale50',
    value: 'sale50'
  },
  {
    label: 'sale55',
    value: 'sale55'
  },
  {
    label: 'sale60',
    value: 'sale60'
  }
];

const packageInputs = computed(() => {
  return [
    {
      label: 'Промокод',
      field: 'code',
      type: 'text'
    },
    {
      label: 'Тип',
      field: 'type',
      type: 'select',
      options: SERVICE_TYPES
    },
    {
      label: 'Активный',
      field: 'is_active',
      type: 'checkbox'
    },
    {
      label: 'Комментарий',
      field: 'comment',
      type: 'textarea'
    }
  ];
});

const PROMOCODE_FIELDS = {
  code: '',
  is_active: false,
  comment: null,
  type: {
    label: 'trial_1_m',
    value: 'trial_1_m'
  }
};

const promocodeFields = ref({
  ...PROMOCODE_FIELDS
});

const isValidTitle = (value) => value.match(/^[а-яА-ЯёЁa-zA-Z+0-9\s]+$/);
const rules = computed(() => {
  return {
    code: {
      required,
      isCyrillicOrLatin: helpers.withMessage('The value must contain Cyrillic or Latin letters', isValidTitle),
      maxLength: maxLength(35)
    },
    comment: {
      maxLength: maxLength(250)
    },
    is_active: {},
    type: {}
  };
});

const v$ = useVuelidate(rules, promocodeFields, { $autoDirty: true });

watch(
  () => props.isOpenModal,
  async () => {
    isOpen.value = props.isOpenModal;
    v$.value.$reset();

    if (props.activeModalName === 'editPromocode') {
      promocodeFields.value.code = props.activeItem.code;
      promocodeFields.value.type = SERVICE_TYPES.find(item => item.value === props.activeItem.type);
      promocodeFields.value.is_active = props.activeItem.is_active;
      promocodeFields.value.comment = props.activeItem.comment;
    } else {
      promocodeFields.value = { ...PROMOCODE_FIELDS };
    }
  }
);

// methods
const getErrorMessage = (field) => {
  return v$.value[field].$errors[0]?.$message;
};

const clearPromocodeFields = () => {
  promocodeFields.value = { ...PROMOCODE_FIELDS };
};

const submit = async (item) => {
  loader.show();

  let endpoint = '';
  const itemId = item?.id;
  switch (props.activeModalName) {
    case 'addPromocode':
      endpoint = apiConstants.PROMOCODES.ADD;
      break;
    default:
      endpoint = apiConstants.PROMOCODES.CHANGE(itemId);
      break;
  }

  const requestParams = JSON.parse(JSON.stringify(promocodeFields.value));

  requestParams.type = requestParams.type.value;

  let response = {};
  let isErrorRequest = false;
  try {
    switch (props.activeModalName) {
      case 'editPromocode':
        response = await requests.put(endpoint, requestParams);
        break;
      default:
        response = await requests.post(endpoint, requestParams);
        break;
    }

    $q.notify({
      message: response.data.message,
      color: 'green'
    });

    v$.value.$reset();
    clearPromocodeFields();
    emit('upd-list');
  } catch (error) {
    isErrorRequest = true;
    $q.notify({
      message: error.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
    if (!isErrorRequest) {
      emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.select-promocode-type {
    height: fit-content !important;
}
</style>
