
import './styles/quasar.scss';
import lang from 'quasar/lang/ru.js';
import '@quasar/extras/material-icons/material-icons.css';
import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  plugins: { Loading, Notify },
  config: {
    notify: {
      position: 'top-right',
      html: true
    }
  },
  lang: lang
};
