<template>
  <div class="row">

    <div class="q-mb-xl col-12 col-lg-2">
      <h5>{{ paymentTitle }}</h5>
      <stats-for-chart
        :stats="stat"
        title="Счета"
      />
    </div>

    <div class="col-12 col-lg-7 table">
      <q-table
        hide-bottom
        :rows="paymentsList"
        :columns="columns"
        separator="cell"
      >
        <template #top>
          <div class="row justify-between items-center full-width">
            <div class="q-table__title">
              Последние неоплаченные счета
            </div>
          </div>
        </template>
      </q-table>
    </div>

  </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import StatsForChart from '@/components/Charts/StatsForChart.vue';

const props = defineProps({
  uncompletedPaymentsData: {
    type: Object,
    default: () => ({})
  }
});

const paymentTitle = computed(() => props.uncompletedPaymentsData?.title);

// stats
const stat = computed(() => props.uncompletedPaymentsData.stat);

// table
const columns = ref([
  { align: 'left', name: 'id', field: 'id', label: '№' },
  { align: 'left', name: 'date', field: 'date', label: 'Дата' },
  { align: 'left', name: 'amount', field: 'amount', label: 'Сумма' },
  { align: 'left', name: 'payerName', field: 'payerName', label: 'Имя плательщика' }
]);

const paymentsList = computed(() => props.uncompletedPaymentsData.lastInvoices);
</script>

<style lang="scss" scoped>
h5 {
  margin-top: 0;
  margin-left: 15px;
}

@media (max-width: 1440px) {
  .table {
    margin-top: 44px;
  }
}
</style>
