export const getErrorMessage = (field) => {
  return field.$errors[0]?.$message;
};

// Form validation
export const isLowerCase = (value) => {
  return value.toLowerCase() === value;
};

export const isUnique = (key, formValue, formValueBuffer, uniqueErrFromApi) => (value) => {
  if (formValue[key] !== formValueBuffer[key]) {
    uniqueErrFromApi = uniqueErrFromApi.filter((errKey) => errKey !== key);
  }
  return !uniqueErrFromApi.includes(key);
};
