<template>
  <q-dialog
    :maximized="true"
    v-model="isOpen"
  >
    <q-card class="workspace">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ modalContent.title }}</div>
        <q-space />
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>
      <q-tabs
        v-model="activeTab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab
          v-for="(tab, idx) in tabs"
          :key="idx"
          :name="tab.name"
          :label="tab.label"
          :disable="tab.isDisable"
        />
      </q-tabs>

      <q-separator />

      <q-tab-panels
        class="workspace__tab"
        v-model="activeTab"
        animated
      >
        <q-tab-panel name="information">
          <workspace-information-form
            :workspace="workspace"
            @update-list="(data) => emit('upd-list', data)"
          />
        </q-tab-panel>
        <q-tab-panel name="subscription">
          <workspace-subscription-tab
            v-if="subscriptionConfig"
            :workspace="workspace"
            :subscription="subscription"
            :subscriptionConfig="subscriptionConfig"
            :isCanRenewal="isCanRenewal"
            @update-list="updateSubscriptionAndList"
          />
        </q-tab-panel>
        <q-tab-panel name="actions">
          <workspace-actions-tab
            :workspace="workspace"
            @update-list="(data) => emit('upd-list', data)"
            @close="() => isOpen = false"
            @delete="v => $emit('delete', v)"
          />
        </q-tab-panel>
        <q-tab-panel name="extensions">
          <workspace-extensions-tab />
        </q-tab-panel>
        <q-tab-panel name="changes">
          <workspace-changes-tab :workspace="workspace" />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, toRef, computed, watch, defineProps, defineEmits } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { apiConstants, requests } from '../../api';
import WorkspaceSubscriptionTab from '@/components/Workspaces/WorkspaceSubscriptionTab.vue';
import WorkspaceInformationForm from '@/components/Workspaces/WorkspaceInformationForm.vue';
import WorkspaceActionsTab from '@/components/Workspaces/WorkspaceActionsTab.vue';
import WorkspaceExtensionsTab from '@/components/Workspaces/WorkspaceExtensionsTab.vue';
import WorkspaceChangesTab from '@/components/Workspaces/WorkspaceChangesTab.vue';

/// Props ///
const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  },
  activeModalName: {
    type: String,
    default: ''
  },
  activeItem: {
    type: Object,
    default: () => ({})
  },
  subscriptionConfigFromList: {
    type: Object,
    required: true
  }
});

// eslint-disable-next-line no-unused-vars
const $q = useQuasar();
const loader = useLoader();

/// Constants ///
const WORKSPACE_FIELDS = {
  id: null,
  is_frozen: false,
  link: '',
  projects_count: null,
  title: '',
  users_count: null
};

const workspaceFields = ref({
  ...WORKSPACE_FIELDS
});

const workspace = toRef(props, 'activeItem');
const subscription = ref(workspace.value);
const subscriptionConfig = ref(props.subscriptionConfig);
const isCanRenewal = ref();

/// Refs ///
const isOpen = ref(false);
const activeTab = ref('subscription');
const tabs = computed(() => {
  return [
    {
      name: 'information',
      label: 'Информация',
      isDisable: false
    },
    {
      name: 'subscription',
      label: 'Подписка',
      isDisable: false
    },
    {
      name: 'actions',
      label: 'Действия',
      isDisable: false
    },
    {
      name: 'extensions',
      label: 'Продления',
      isDisable: !subscription.value
    },
    {
      name: 'changes',
      label: 'Изменения',
      isDisable: !subscription.value
    }
  ];
});

/// Computed ///
const modalContent = computed(() => {
  switch (props.activeModalName) {
    case 'showInfo':
      return {
        title: 'Подробнее',
        action: 'Сохранить'
      };
    default:
      return {};
  }
});

/// Emits ///
// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['close', 'upd-list', 'delete']);

const getWorkspaceInfo = async () => {
  if (!props.activeItem || !Object.keys(props.activeItem).length) { return; };
  loader.show();
  try {
    const response = await requests.get(apiConstants.WORKSPACES.INFO(props.activeItem.id));
    subscription.value = response.data.subscription;
    subscriptionConfig.value = response.data.subscriptionConfig;
    isCanRenewal.value = response.data.canRenewal;
  } catch (e) {
    console.error(e);
  } finally {
    loader.hide();
  }
};

/// Watchers ///
watch(
  () => props.isOpenModal,
  async () => {
    isOpen.value = props.isOpenModal;

    if (!props.isOpenModal) {
      subscriptionConfig.value = null;
      activeTab.value = 'subscription';
    }

    workspace.value = props.activeItem;
    subscription.value = props.activeItem.subscription;

    getWorkspaceInfo();
  }
);

const updateSubscriptionAndList = (data) => {
  workspace.value = data;
  subscription.value = data.subscription;
  emit('upd-list', data);
};
</script>

<style lang="scss" scoped>
.workspace {

  &__title {
    margin-top: 12px;
  }

  &__tab {
    width: 100%;
  }
}
</style>
