const mutation = {

  updateProjectList (state, payload) {
    state.projectList = payload;
  },
  updateProjectItem (state, payload) {
    for (let i = 0; i < state.projectList.length; i++) {
      if (state.projectList[i].id === payload.id) {
        state.projectList[i] = payload;
      }
    }
  },
  updateTariffList (state, payload) {
    state.tariffList = payload;
  }
};

export default mutation;
