<template>
  <div class="row">
    <div class="col-12 col-lg-4">
      <h5>{{ paymentTitle }}</h5>
      <div>
        <q-tabs
          v-model="activeTabName"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="left"
        >
          <q-tab
            v-for="(tab, idx) in tabs"
            :key="idx"
            :name="tab.name"
            :label="tab.label"
          />
        </q-tabs>
        <line-chart
          class="q-mt-lg"
          :chartName="chart.name"
          :axisX="axisX"
          :axisY="axesY[chart.entitiesName]"
        />
        <stats-for-chart
          :stats="stats[chart.entitiesName]"
          :title="chart.statsTitle"
          class="q-mt-lg"
        />
      </div>
    </div>
    <div class="col-12 col-lg-8 table">
      <q-table
        hide-bottom
        :rows="paymentsList"
        :columns="columns"
        separator="cell"
      >
        <template #top>
          <div class="row justify-between items-center full-width">
            <div class="q-table__title">
              Последние оплаты
            </div>
          </div>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import LineChart from '@/components/Charts/LineChart.vue';
import StatsForChart from '@/components/Charts/StatsForChart.vue';

const props = defineProps({
  completedPaymentsData: {
    type: Object,
    default: () => ({})
  }
});

const paymentTitle = computed(() => props.completedPaymentsData?.title);

// tabs
const tabs = ref([
  {
    name: 'ByYears',
    label: 'По годам',
    key: 'year'
  },
  {
    name: 'ByMonths',
    label: 'По месяцам',
    key: 'month'
  },
  {
    name: 'ByWeeks',
    label: 'По неделям',
    key: 'week'
  }
]);

const activeTabName = ref('ByMonths');
const activeTab = computed(() => tabs.value.find((tab) => tab.name === activeTabName.value));

// charts
const chart = ref(
  {
    name: 'Оплаты',
    entitiesName: 'payments',
    statsTitle: 'Оплаты'
  }
);

const chartsData = computed(() => {
  return props.completedPaymentsData?.periods?.[activeTab.value.key];
});

const axisX = computed(() => Object.values(chartsData.value).map((point) => point.title));

const axesY = computed(() => {
  return {
    payments: Object.values(chartsData.value).map((item) => item.amount)
  };
});

// stats
const stats = computed(() => {
  return { payments: props.completedPaymentsData.stat };
});

// table
const columns = ref([
  { align: 'left', name: 'id', field: 'id', label: '№' },
  { align: 'left', name: 'date', field: 'date', label: 'Дата' },
  { align: 'left', name: 'amount', field: 'amount', label: 'Сумма' },
  { align: 'left', name: 'payerName', field: 'payerName', label: 'Имя плательщика' }
]);

const paymentsList = computed(() => props.completedPaymentsData.lastPayments);
</script>

<style lang="scss" scoped>
h5 {
  margin-top: 0;
  margin-left: 15px;
}

.table {

  .q-table__container {
    margin-left: 40px;
  }
}

@media (max-width: 1440px) {
  .table {
    .q-table__container {
      margin-left: 0;
    }
  }
}
</style>
