<template>
  <q-dialog v-model="isOpen">
    <q-card :class="{ 'w-auto': activeModalName === 'delete' }">
      <q-card-section class="row items-center">
        <div class="text-h6">{{ modalContent.title }}</div>
        <q-space />
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="emit('close')"
        />
      </q-card-section>
      <q-card-section>
        <q-form
          @submit.prevent="submit"
          class="q-gutter-md"
        >
          <template v-if="isShowForm">
            <q-input
              v-model="v$.title.$model"
              :error="v$.title.$error"
              :error-message="getErrorMessage(v$['title'])"
              label="Название"
              class="col col-md-6 q-mb-sm"
              filled
            />
            <q-input
              v-model="v$.company.$model"
              :error="v$.company.$error"
              :error-message="getErrorMessage(v$['company'])"
              label="Организация"
              class="col col-md-6 q-mb-sm"
              filled
            />
            <q-input
              v-model="v$.person_name.$model"
              :error="v$.person_name.$error"
              :error-message="getErrorMessage(v$['person_name'])"
              label="Пользователь"
              class="col col-md-6 q-mb-sm"
              filled
            />
            <q-input
              v-model="v$.person_position.$model"
              :error="v$.person_position.$error"
              :error-message="getErrorMessage(v$['person_position'])"
              label="Должность"
              class="col col-md-6 q-mb-sm"
              filled
            />
            <q-input
              v-model="v$.description.$model"
              :error="v$.description.$error"
              :error-message="getErrorMessage(v$['description'])"
              label="Описание"
              class="col col-md-6 q-mb-sm"
              filled
              type="textarea"
            />
            <q-input
              v-model="v$.text.$model"
              :error="v$.text.$error"
              :error-message="getErrorMessage(v$['text'])"
              label="Текст"
              class="col col-md-6 q-mb-sm"
              filled
              type="textarea"
            />
            <q-file
              v-model="img"
              accept="image/png, image/jpeg"
              label="Pick one file"
              filled
              class="col col-md-6 q-mb-sm"
            />
            <q-checkbox
              v-model="activeStatus"
              label="Отзыв активен?"
            />
          </template>
          <div class="actions">
            <q-btn
              color="blue-grey"
              no-caps
              @click="$emit('close')"
            >
              {{ modalContent.cancel }}
            </q-btn>
            <q-btn
              :color="activeModalName === 'delete' ? 'red' : 'primary'"
              no-caps
              type="submit"
            >
              {{ modalContent.action }}
            </q-btn>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { apiConstants, requests } from '@/api';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { getErrorMessage } from '@/utils/methods.js';

const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  },
  activeModalName: {
    type: String,
    default: ''
  },
  activeItem: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['close', 'upd-list', 'update:isOpenModal']);

const $q = useQuasar();
const loader = useLoader();

const isOpen = computed({
  get: () => props.isOpenModal,
  set: (value) => emit('update:isOpenModal', value)
});

const modalContent = computed(() => {
  switch (props.activeModalName) {
    case 'create':
      return {
        title: 'Создание отзыва',
        action: 'Сохранить',
        cancel: 'Отменить'
      };
    case 'edit':
      return {
        title: 'Редактирование отзыва',
        action: 'Сохранить',
        cancel: 'Отменить'
      };
    case 'delete':
      return {
        title: 'Удалить отзыв?',
        action: 'Удалить',
        cancel: 'Отменить'
      };
    default:
      return {};
  }
});

const isShowForm = computed(() => props.activeModalName !== 'delete');

const formValue = ref({
  title: '',
  company: '',
  person_name: '',
  person_position: '',
  description: '',
  text: '',
  is_active: 0
});

const img = ref([]);

const activeStatus = ref(false);
watch(
  () => activeStatus.value,
  () => {
    formValue.value.is_active = activeStatus.value ? 1 : 0;
  }
);

// validations
const rules = computed(() => {
  return {
    title: { required },
    company: {},
    description: {},
    text: {},
    person_name: {},
    person_position: {},
    img: [],
    is_active: {}
  };
});

const v$ = useVuelidate(rules, formValue, { $autoDirty: true });

watch(
  () => props.activeItem,
  () => {
    if (props.activeItem) {
      formValue.value.title = props.activeItem?.row?.title ? props.activeItem?.row?.title : '';
      formValue.value.company = props.activeItem?.row?.company ? props.activeItem?.row?.company : '';
      formValue.value.description = props.activeItem?.row?.description ? props.activeItem.row.description : '';
      formValue.value.text = props.activeItem?.row?.text ? props.activeItem.row.text : '';
      formValue.value.person_name = props.activeItem?.row?.person_name ? props.activeItem.row.person_name : '';
      formValue.value.person_position = props.activeItem?.row?.person_position ? props.activeItem.row.person_position : '';
      activeStatus.value = !!props.activeItem?.row?.is_active;
      v$.value.$reset();
    }
  });

const submit = async (event) => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect && isShowForm.value) { return; };

  // prepare data
  const formData = new FormData();
  if (props.activeModalName !== 'delete') {
    if (event.target[6].files[0]) { formData.append('img', event.target[6].files[0]); }
    Object.keys(formValue.value).forEach((field) => {
      if (formValue.value[field] !== '') {
        formData.append(`${field}`, formValue.value[field]);
      }
    });
  }

  loader.show(500);
  try {
    let response;
    if (props.activeModalName === 'create') {
      const endpoint = apiConstants.REVIEWS.LIST;
      response = await requests.post(endpoint, formData);
    } else if (props.activeModalName === 'edit') {
      const endpoint = apiConstants.REVIEWS.UPDATE(props.activeItem.row.id);
      response = await requests.post(endpoint, formData);
    } else {
      const endpoint = apiConstants.REVIEWS.DELETE(props.activeItem.row.id);
      response = await requests.delete(endpoint);
    }

    img.value = [];
    emit('close');
    emit('upd-list');
    $q.notify({
      message: response.data.message,
      color: 'green'
    });
  } catch (e) {
    console.log(e);
    $q.notify({
      message: e.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
  }
};

</script>

<style lang="scss">
.q-dialog {
  &__inner {
    .q-card {
      width: 100%;

      &.w-auto {
        width: auto !important;
      }
    }
  }
}
</style>
