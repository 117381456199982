import useQuasar from 'quasar/src/composables/use-quasar.js';;

export function useLoader () {
  const $q = useQuasar();

  const show = (delay = 0) => {
    $q.loading.show({
      delay: delay
    });
  };

  const hide = () => {
    $q.loading.hide();
  };

  return {
    show,
    hide
  };
}
