<template>
  <q-card class="full-width full-height">
    <q-tabs
      v-model="activeTab"
      dense
      class="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="justify"
      narrow-indicator
    >
      <q-tab
        v-for="(tab, idx) in tabs"
        :key="idx"
        :name="tab.name"
        :label="tab.label"
      />
    </q-tabs>
  </q-card>
  <component :is="activeComponent" />
</template>

<script setup>
import { ref, computed } from 'vue';
import Categories from './BlogCategories.vue';
import Posts from './BlogPosts.vue';

const activeTab = ref('categories');
const tabs = ref([
  {
    name: 'categories',
    label: 'Категории',
    component: Categories
  },
  {
    name: 'posts',
    label: 'Посты',
    component: Posts
  }
]);
const activeComponent = computed(() => tabs.value.find((tab) => tab.name === activeTab.value).component);
</script>

<style lang="scss" scoped></style>
