<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'LayoutDefault',
  setup () {
    return {
      leftDrawerOpen: ref(false)
    };
  }
};
</script>
