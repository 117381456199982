<template>
  <q-table :rows="items" :columns="columns" row-key="name" />
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
  items: {
    type: Array,
    required: true
  }
});

const columns = [
  { name: 'id', label: 'id', field: 'id', align: 'left' },
  { name: 'Title', label: 'Title', field: 'title', align: 'left' },
  { name: 'workspace', label: 'Workspace', field: (row) => row?.workspace?.title, align: 'left' },
  { name: 'author', label: 'Автор', field: (row) => row?.author?.name, align: 'left' },
  { name: 'date', label: 'Дата', field: 'date', align: 'left' },
  {
    name: 'owner',
    label: 'Владелец',
    field: (row) => {
      console.log(row);
      return row?.author?.name;
    },
    align: 'left'
  },
  { name: 'users', label: 'Пользователи', field: (row) => row?.counters?.users, align: 'center' },
  { name: 'cases', label: 'Кейсы', field: (row) => row?.counters?.cases, align: 'center' },
  { name: 'runs', label: 'Раны', field: (row) => row?.counters?.runs, align: 'center' },
  { name: 'plans', label: 'Планы', field: (row) => row?.counters?.plans, align: 'center' },
  { name: 'users', label: 'Пользователи', field: (row) => row?.counters?.users, align: 'center' },
  { name: 'defects', label: 'Дефекты', field: (row) => row?.counters?.defects, align: 'center' },
  { name: 'milestone', label: 'Майлстоунс', field: (row) => row?.counters?.milestone, align: 'center' },
  { name: 'checklist', label: 'Чек-листы', field: (row) => row?.counters?.checklist, align: 'center' },
  { name: 'last_action', label: 'Последняя активность', field: 'last_action', align: 'left' },
  { name: 'is_archived', label: 'Архив', field: (row) => row?.is_archived ? 'Да' : 'Нет', align: 'left' }
];
</script>
