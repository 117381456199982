import { setToken, unsetToken } from '@/utils/token';
import { apiConstants, requests } from '../../api';

const actions = {
  async login ({ commit }, payload) {
    try {
      const { data } = await requests.post(apiConstants.AUTH.LOGIN, payload);

      if (!data.is_admin) {
        return;
      }

      commit('updateToken', data.token);
      setToken(data.token);
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 400) {
        console.log('Incorrect e-mail or password ');
      } else {
        console.log(`${e.response.status}: ${e.response.statusText}`);
      }
    }
  },
  logout ({ commit }) {
    unsetToken();
    commit('updateToken', null);
    commit('updateUser', null);
  }
};
export default actions;
