<template>
  <q-dialog v-model="isOpen">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ modalContent.title }}</div>
        <q-space />
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>
      <q-card-section v-if="props.activeModalName === 'addPackage' || props.activeModalName === 'editPackage'">
        <div class="row q-col-gutter-md">
          <template v-for="(input, idx) in packageInputs" :key="idx">
            <!-- Text -->
            <q-input
              v-if="input.type === 'text'"
              v-model="v$[input.field].$model"
              :error="v$[input.field].$error"
              :error-message="getErrorMessage(input.field)"
              :label="input.label"
              class="col col-md-6"
              filled
            />
            <!-- Select -->
            <q-select
              v-if="input.type === 'select'"
              filled
              v-model="v$[input.field].$model"
              :error="v$[input.field].$error"
              :error-message="getErrorMessage(input.field)"
              class="col col-md-6"
              :options="input?.options ?? []"
              :label="input.label"
            />
            <!-- Number -->
            <q-input
              v-if="input.type === 'number'"
              v-model.number="v$[input.field].$model"
              type="number"
              :error="v$[input.field].$error"
              :error-message="getErrorMessage(input.field)"
              :label="input.label"
              class="col col-md-6"
              filled
            />
            <!-- Checkbox -->
            <q-checkbox
              v-if="input.type === 'checkbox'"
              v-model="v$[input.field].$model"
              size="sm"
              class="col col-md-6"
              :label="input.label"
            />
          </template>
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn
          type="submit"
          color="primary"
          no-caps
          @click="submit(props.activeItem)"
        >{{ modalContent.action }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { useVuelidate } from '@vuelidate/core';
import { required, integer, helpers, minLength, minValue } from '@vuelidate/validators';
import { apiConstants, requests } from '../../api';

const $q = useQuasar();
const loader = useLoader();

const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  },
  activeModalName: {
    type: String,
    default: ''
  },
  activeItem: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits(['close', 'upd-list']);

const isOpen = ref(false);

const modalContent = computed(() => {
  switch (props.activeModalName) {
    case 'editPackage':
      return {
        title: 'Редактирование пакета',
        action: 'Сохранить'
      };
    case 'addPackage':
      return {
        title: 'Создание пакета',
        action: 'Сохранить'
      };
    default:
      return {};
  }
});

const SERVICE_TYPES = [
  {
    label: 'Диск',
    value: 'disk'
  },
  {
    label: 'Пользователи',
    value: 'user'
  }
];

const packageInputs = computed(() => {
  return [
    {
      label: 'Название',
      field: 'title',
      type: 'text'
    },
    {
      label: 'Доступность',
      field: 'is_active',
      type: 'checkbox'
    },
    {
      label: 'Тип',
      field: 'type',
      type: 'select',
      options: SERVICE_TYPES
    },
    {
      label: 'Цена',
      field: 'price',
      type: 'number'
    },
    {
      label: 'Скидка',
      field: 'discount',
      type: 'number'
    },
    {
      label: 'Количество пространства на диске',
      field: 'storage',
      type: 'number'
    },
    {
      label: 'Количество пользователей',
      field: 'users',
      type: 'number'
    }
  ];
});

const PACKAGE_FIELDS = {
  title: '',
  price: null,
  is_active: false,
  discount: null,
  type: {
    label: 'Диск',
    value: 'disk'
  },
  users: 0,
  storage: 0
};

const packageFields = ref({
  ...PACKAGE_FIELDS
});

const isValidTitle = (value) => value.match(/^[а-яА-ЯёЁa-zA-Z+0-9\s]+$/);
const rules = computed(() => {
  return {
    title: {
      required,
      isCyrillicOrLatin: helpers.withMessage('The value must contain Cyrillic or Latin letters', isValidTitle),
      minLength: minLength(1)
    },
    users: {
      integer,
      minValue: minValue(0)
    },
    storage: {
      integer,
      minValue: minValue(0)
    },
    price: {
      required,
      integer,
      minValue: minValue(0)
    },
    is_active: {},
    type: {},
    discount: {
      required,
      integer,
      minValue: minValue(0)
    }
  };
});

const v$ = useVuelidate(rules, packageFields, { $autoDirty: true });

watch(
  () => props.isOpenModal,
  async () => {
    isOpen.value = props.isOpenModal;
    v$.value.$reset();

    if (props.activeModalName === 'editPackage') {
      packageFields.value = { ...props.activeItem.row, ...props.activeItem.row.properties };

      // Find a right one service type
      const serviceType = SERVICE_TYPES.find(type => type.value === props.activeItem.row.type);

      if (serviceType) {
        packageFields.value.type = serviceType;
      }
    }
  }
);

// methods
const getErrorMessage = (field) => {
  return v$.value[field].$errors[0]?.$message;
};

const clearPackageFields = () => {
  packageFields.value = { ...PACKAGE_FIELDS };
};

const submit = async (item) => {
  loader.show();

  let endpoint = '';
  const itemId = item?.row?.id;
  switch (props.activeModalName) {
    case 'addPackage':
      endpoint = apiConstants.PACKAGES.ADD;
      break;
    default:
      endpoint = apiConstants.PACKAGES.CHANGE(itemId);
      break;
  }

  // Include users and storage into properties
  const PROPERTIES_KEYS = ['users', 'storage'];
  const requestParams = JSON.parse(JSON.stringify(packageFields.value));
  requestParams.properties = {};

  for (const key of PROPERTIES_KEYS) {
    requestParams.properties[key] = requestParams[key];
    delete requestParams[key];
  }

  requestParams.type = requestParams.type.value;

  let response = {};
  let isErrorRequest = false;
  try {
    switch (props.activeModalName) {
      case 'editPackage':
        response = await requests.put(endpoint, requestParams);
        break;
      default:
        response = await requests.post(endpoint, requestParams);
        break;
    }

    $q.notify({
      message: response.data.message,
      color: 'green'
    });

    v$.value.$reset();
    clearPackageFields();
    emit('upd-list');
  } catch (error) {
    isErrorRequest = true;
    $q.notify({
      message: error.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
    if (!isErrorRequest) {
      emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
