<template>
  <div class="extensions">
    now there is empty WIP
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>
