<template>
  <div class="full-width full-height relative-position">
    <q-card class="container">
      <q-table
        class="feedback__table"
        ref="tableRef"
        :loading="loading"
        :rows="feedbackList"
        :columns="columns"
        :pagination="pagination"
        @request="onRequest"
        hide-pagination
        separator="cell"
      >
        <template #top>
          <div class="row justify-between items-center full-width">
            <div class="q-table__title">
              Обращения
            </div>
          </div>
        </template>
        <template #body-cell-id="item">
          <q-td class="relative-position overflow-hidden">
            <div
              @click="toggleModal(true, 'comment', item)"
              class="feedback-link"
            >
              {{ item.row?.id || '-' }}
            </div>
          </q-td>
        </template>
        <template #body-cell-actions="item">
          <q-td class="relative-position overflow-hidden">
            <q-btn
              size="xs"
              round
              icon="list"
              flat
              class="cursor-pointer"
              @click="activeContext = item.key"
            >
              <q-menu
                transition-show="jump-down"
                transition-hide="jump-up"
                :offset="[50, 10]"
              >
                <q-list v-if="!item.row.is_completed" style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'markAsRead', item)"
                  >
                    <q-item-section>
                      Отметить обработанным
                    </q-item-section>
                  </q-item>
                </q-list>
                <q-list v-else style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'markAsUnread', item)"
                  >
                    <q-item-section>
                      Вернуть в работу
                    </q-item-section>
                  </q-item>
                </q-list>
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'deleteItem', item)"
                  >
                    <q-item-section>
                      Удалить
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </q-card>
    <div class="row justify-center q-mt-md">
      <q-pagination
        v-model="currentPage"
        color="grey-8"
        :max="pagination.max"
        size="sm"
      />
  </div>
  <feedback-modal
    :is-open-modal="isOpenModal"
    :active-modal-name="activeModalName"
    :item-id="activeItemId"
    :active-item="activeItem.row"
    @close="toggleModal(false)"
    @upd-list="fetchFeedbackList"
  />
</div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { apiConstants, requests } from '../../api';
import feedbackModal from '@/components/Modals/FeedbackModal';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();
/// Utils ///
const getFeedbackType = (type) => {
  switch (type) {
    case 'request':
      return 'Заявка';
    case 'feedback':
      return 'Обратная связь';
    default:
      return 'Поддержка';
  }
};

/// Refs ///
const loading = ref(true);

const tableRef = ref();
const pagination = ref({
  sortBy: 'desc',
  descending: false,
  rowsPerPage: 50,
  max: 1
});
const currentPage = ref(1);

const feedbackList = ref([]);

const columns = ref([
  { align: 'left', name: 'id', field: 'id', label: 'ID' },
  { align: 'left', name: 'name', field: 'name', label: 'Контактное лицо' },
  { align: 'left', name: 'phone', field: 'phone', label: 'Телефон' },
  { align: 'left', name: 'email', field: 'email', label: 'Почта' },
  { align: 'left', name: 'type', field: row => getFeedbackType(row.payment_type), label: 'Тип' },
  { align: 'left', name: 'date', field: 'date', label: 'Дата' },
  { align: 'left', name: 'is_completed', field: item => item.is_completed ? 'Да' : 'Нет', label: 'Обработано' },
  { align: 'left', name: 'actions' }
]);

// Modals data
const activeItem = ref({});
const isOpenModal = ref(false);
const activeModalName = ref('');

/// Computed ///
const activeItemId = computed(() => {
  return activeItem.value?.row?.id ?? -1;
});

/// Handlers ///
const fetchFeedbackList = async (page) => {
  loading.value = true;

  let endpoint = apiConstants.FEEDBACK.LIST;
  if (page) {
    endpoint += `?page=${page}`;
  }

  try {
    const { data: feedbackResponse } = await requests.get(endpoint);
    feedbackList.value = feedbackResponse.data;
    // Meta
    pagination.value.rowsPerPage = feedbackResponse.meta.per_page;
    pagination.value.max = feedbackResponse.meta.last_page;
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    console.error(e);
  } finally {
    loading.value = false;
  }
};

/// Watch ///
watch(currentPage, async () => {
  await fetchFeedbackList(currentPage.value);
});

const setActiveItem = (item) => {
  activeItem.value = item;
};

const toggleModal = (status, modalName = '', item = {}) => {
  isOpenModal.value = status;
  activeModalName.value = modalName;
  console.info(item);
  setActiveItem(item);
};

const onRequest = async (props) => {
  await fetchFeedbackList();
};

/// Hooks ///
onMounted(() => {
  tableRef.value.requestServerInteraction();
});
</script>

<style lang="scss" scoped>
.feedback-link {
  color: rgb(99, 99, 240);
  text-decoration: underline;
  cursor: pointer;
}
</style>
