<template>
  <div class="chart">
    <div class="title text-center text-h6">
      {{ chartName }}
      <q-btn
        icon="visibility"
        @click="isOpenChartModal = true"
      >
        <q-tooltip>
          Открыть график
        </q-tooltip>
      </q-btn>
    </div>
    <div :id="`chart-${chartName}`" />
    <q-dialog
      :maximized="true"
      v-model="isOpenChartModal"
    >
      <q-card>
        <q-card-section class="title text-center text-h6">
          {{ chartName }}
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>
        <q-card-section class="modal-chart">
          <div :id="`chart-${chartName}-in-modal`" />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineProps, nextTick } from 'vue';
import ApexCharts from 'apexcharts';

const props = defineProps({
  axisX: {
    type: Array,
    required: true
  },
  axisY: {
    type: Array,
    required: true
  },
  chartName: {
    type: String,
    required: true
  }
});

const options = computed(() => {
  return {
    chart: {
      type: 'line'
    },
    series: [
      {
        name: props.chartName,
        data: props.axisY
      }
    ],
    xaxis: {
      categories: props.axisX
    }
  };
});

const chart = ref(null);
const isOpenChartModal = ref(false);

const renderChart = (chartId) => {
  const el = document.getElementById(chartId);
  el.innerHTML = '';
  chart.value = new ApexCharts(el, options.value);
  chart.value.render();
};

watch(
  () => options.value,
  () => renderChart(`chart-${props.chartName}`),
  { deep: true }
);

watch(() => isOpenChartModal.value, () => nextTick(() => renderChart(`chart-${props.chartName}-in-modal`)));

onMounted(() => {
  renderChart(`chart-${props.chartName}`);
});
</script>

<style lang="scss" scoped>
.chart {
  .title {
    position: relative;
    margin-bottom: 10px;

    button {
      position: absolute;
      right: 0;
    }
  }

}

.modal-chart {
  max-width: 70%;
  margin: auto;
}
</style>
