<template>
  <div class="stats">
    <div class="text-h6">{{ title }}</div>
    <div
      v-for="(stat, idx) in statsFields"
      :key="idx"
      class="stat"
    >
      <span>{{ stat.title }}</span>
      <span>{{ stats[stat.key] }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs } from 'vue';

const props = defineProps({
  stats: {
    type: Object,
    required: true
  },
  title: {
    type: String,
    required: false
  }
});

const { stats, title } = toRefs(props);

const statsFields = ref([
  {
    title: 'Сегодня',
    key: 'today'
  },
  {
    title: 'Вчера',
    key: 'yesterday'
  },
  {
    title: 'Текущая неделя',
    key: 'week'
  },
  {
    title: 'Текущий месяц',
    key: 'month'
  },
  {
    title: 'Текущий год',
    key: 'year'
  }
]);
</script>

<style lang="scss" scoped>
.stats {
  padding: 0 40px;
  display: grid;
  gap: 10px;
  max-width: 280px;

  .stat {
    display: flex;
    justify-content: space-between;
  }
}
</style>
