<template>
  <div class="full-width full-height relative-position">
    <q-card class="container">
      <q-table
        class="tariffs__table"
        ref="tableRef"
        hide-bottom
        :loading="loading"
        :rows="tariffsList"
        :columns="columns"
        :pagination="pagination"
        @request="onRequest"
        separator="cell"
      >
        <template #top>
          <div class="row justify-between items-center full-width">
            <div class="q-table__title">
              Тарифы
            </div>
            <div>
              <q-btn @click="toggleModal(true, 'createTariff')">Добавить тариф</q-btn>
            </div>
          </div>
        </template>
        <template #body-cell-actions="item">
          <q-td class="relative-position overflow-hidden">
            <q-btn
              size="xs"
              round
              icon="list"
              flat
              class="cursor-pointer"
              @click="activeContext = item.key"
            >
              <q-menu
                transition-show="jump-down"
                transition-hide="jump-up"
                :offset="[50, 10]"
              >
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'showTariffDetail', item)"
                  >
                    <q-item-section>
                      Подробнее
                    </q-item-section>
                  </q-item>
                </q-list>
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'editTariff', item)"
                  >
                    <q-item-section>
                      Редактировать
                    </q-item-section>
                  </q-item>
                </q-list>
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'deleteTariff', item)"
                  >
                    <q-item-section>
                      Удалить
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </q-card>
    <form-tariff-modal
      :isOpenModal="isOpenModal"
      :activeModalName="activeModalName"
      :activeItem="activeItem"
      @hide="toggleModal(false)"
      @close="toggleModal(false)"
      @upd-list="fetchTariffsList"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { apiConstants, requests } from '../../api';
import FormTariffModal from '@/components/Modals/FormTariffModal';

// Table
const loading = ref(true);

const tableRef = ref();

const tariffsList = ref([]);

const columns = ref([
  { align: 'left', name: 'id', field: 'id', label: '#' },
  { align: 'left', name: 'title', field: 'title', label: 'Title' },
  { align: 'left', name: 'alias', field: 'alias', label: 'Alias' },
  { align: 'left', name: 'storage_size', field: (item) => item.properties.storage_size, label: 'SS' },
  { align: 'left', name: 'user_limit', field: (item) => item.properties.user_limit, label: 'UL' },
  { align: 'left', name: 'case_limit', field: (item) => item.properties.case_limit, label: 'CL' },
  { align: 'left', name: 'run_limit', field: (item) => item.properties.run_limit, label: 'RL' },
  { align: 'left', name: 'plan_limit', field: (item) => item.properties.plan_limit, label: 'PL' },
  { align: 'left', name: 'defect_limit', field: (item) => item.properties.defect_limit, label: 'DL' },
  { align: 'left', name: 'milestone_limit', field: (item) => item.properties.milestone_limit, label: 'ML' },
  { align: 'left', name: 'price', field: 'price', label: 'price' },
  { align: 'left', name: 'actions' }
]);

const pagination = ref({
  rowsPerPage: 50
});

const fetchTariffsList = async (page) => {
  loading.value = true;

  let endpoint = apiConstants.TARIFFS.LIST;
  if (page) {
    endpoint += `?page=${page}`;
  }

  try {
    const { data: tariffResponse } = await requests.get(endpoint);
    tariffsList.value = tariffResponse.data;
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};

const onRequest = async (props) => {
  await fetchTariffsList();
};

onMounted(() => {
  tableRef.value.requestServerInteraction();
});

// Modals data
const activeItem = ref({});
const isOpenModal = ref(false);
const activeModalName = ref('');

const setActiveItem = (item) => {
  activeItem.value = item;
};

const toggleModal = (status, modalName = '', item = {}) => {
  isOpenModal.value = status;
  activeModalName.value = modalName;
  setActiveItem(item);
};
</script>

<style lang="scss" scoped>

</style>
