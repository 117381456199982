<template>
  <div>
    <q-table
      class="categories__table q-mt-lg"
      ref="tableRef"
      :loading="loading"
      :rows="categoriesList"
      :rows-per-page-options="[20, 40, 60, 80, 100]"
      :columns="columns"
      separator="cell"
    >
      <template #top>
        <div class="row justify-between items-center full-width">
          <div class="q-table__title">
            Категории
          </div>
          <q-btn @click="toggleModal(true, 'create')">Добавить категорию</q-btn>
        </div>
      </template>
      <template #body-cell-actions="item">
        <q-td class="relative-position overflow-hidden">
          <q-btn
            size="xs"
            round
            icon="list"
            flat
            class="cursor-pointer"
            @click="activeContext = item.key"
          >
            <q-menu
              transition-show="jump-down"
              transition-hide="jump-up"
              :offset="[50, 10]"
            >
              <q-list style="min-width: 100px">
                <q-item
                  clickable
                  @click="toggleModal(true, 'edit', item)"
                  v-close-popup
                >
                  <q-item-section>
                    Редактировать
                  </q-item-section>
                </q-item>
              </q-list>
              <q-list style="min-width: 100px">
                <q-item
                  clickable
                  v-close-popup
                  @click="toggleModal(true, 'delete', item)"
                >
                  <q-item-section>
                    Удалить
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <blog-category-modal
      v-model="isOpenModal"
      :activeModalName="activeModalName"
      :activeItem="activeItem"
      @close="toggleModal(false)"
      @upd-list="fetchCategoriesList"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { apiConstants, requests } from '@/api';
import BlogCategoryModal from './BlogCategoryModal.vue';

/// Refs ///
const loading = ref(true);

const tableRef = ref();

const categoriesList = ref([]);

const columns = ref([
  { align: 'left', name: 'id', field: 'id', label: 'ID' },
  { align: 'left', name: 'title', field: 'title', label: 'Название' },
  { align: 'left', name: 'description', field: 'description', label: 'Описание' },
  { align: 'left', name: 'is_completed', field: item => item.is_active ? 'Да' : 'Нет', label: 'Активна (да/нет)' },
  { align: 'left', name: 'actions' }
]);

// Modals data
const activeItem = ref(null);
const isOpenModal = ref(false);
const activeModalName = ref('');

/// Handlers ///
const fetchCategoriesList = async () => {
  loading.value = true;

  const endpoint = apiConstants.BLOG.CATEGORIES.LIST;

  try {
    const { data: invoicesResponse } = await requests.get(endpoint);
    categoriesList.value = invoicesResponse.data;
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const setActiveItem = (item) => {
  activeItem.value = item;
};

const toggleModal = (status, modalName = '', item = {}) => {
  isOpenModal.value = status;
  activeModalName.value = modalName;
  setActiveItem(item);
};

fetchCategoriesList();
</script>

<style lang="scss" scoped></style>
