<template>
  <div
    class="left-sidebar"
    id="left-navigation-sidebar"
  >
    <div class="left-sidebar__header">
      <div class="left-sidebar__menu-head">Вкладки</div>
      <div class="left-sidebar__quit">
        <q-btn
          color="primary"
          text-color="white"
          size="sm"
          icon="logout"
          padding="4px"
          @click="logout"
        />
      </div>
    </div>
    <div class="left-sidebar__line"></div>
    <div class="left-sidebar__content">
      <router-link
        class="left-sidebar__menu-item-link"
        :to="menuItem.url"
        :key="mIndex"
        v-for="(menuItem, mIndex) in menuItems"
      >
        <div :class="{
          'left-sidebar__menu-item': true,
          'left-sidebar__menu-item_active': isActiveMenuItem(menuItem?.url),
        }">
          <div class="left-sidebar__menu-item-text-wrapper">
            <span class="left-sidebar__menu-item-text">{{ menuItem.title }}</span>
          </div>
        </div>
      </router-link>
    </div>

    <div class="left-sidebar__header">
      <div class="left-sidebar__menu-head">Контент</div>
    </div>
    <div class="left-sidebar__line"></div>
    <div class="left-sidebar__content">
      <router-link
        class="left-sidebar__menu-item-link"
        :to="menuItem.url"
        :key="mIndex"
        v-for="(menuItem, mIndex) in menuContent"
      >
        <div :class="{
          'left-sidebar__menu-item': true,
          'left-sidebar__menu-item_active': isActiveMenuItem(menuItem?.url),
        }">
          <div class="left-sidebar__menu-item-text-wrapper">
            <span class="left-sidebar__menu-item-text">{{ menuItem.title }}</span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>

import { ref } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();
const menuItems = ref([
  { url: { name: 'Home' }, title: 'Главная' },
  { url: { name: 'ProjectList' }, title: 'Проекты' },
  { url: { name: 'UsersList' }, title: 'Пользователи' },
  { url: { name: 'PackagesList' }, title: 'Пакеты подписки' },
  { url: { name: 'WorkspacesList' }, title: 'Пространства' },
  { url: { name: 'PromoCodes' }, title: 'Промокоды' },
  { url: { name: 'InvoicesList' }, title: 'Счета' },
  { url: { name: 'LicensesList' }, title: 'Лицензии' },
  // { url: { name: 'TariffsList' }, title: 'Тарифы' }
  { url: { name: 'FeedbackList' }, title: 'Обращения' },
  { url: { name: 'Dashboard' }, title: 'Dashboard' }
]);
const menuContent = ref([
  { url: { name: 'Blog' }, title: 'Блог' },
  { url: { name: 'Reviews' }, title: 'Отзывы' }
]);

const isActiveMenuItem = (url) => {
  return router.currentRoute.value.name === url.name;
};

const logout = async () => {
  await store.dispatch('auth/logout');
  await router.push({ name: 'Login' });
};

</script>

<style lang="scss">
.left-sidebar {
  box-sizing: border-box;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: var(--left-menu-width);
  padding: 0 0 35px 0;
  overflow: hidden;
  background-color: var(--color-bg3);
  border-top: 1px solid var(--color-border2);
  border-right: 1px solid var(--color-border2);
  transition: width 0.3s ease;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__quit {
    font-size: 12px;
    margin-right: 20px;
  }

  &__content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    // height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

  &__menu-head {
    display: flex;
    align-items: center;
    color: #d97f3d;
    padding: 7px 20px;
    font-size: 1.1rem;
  }

  &__line {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid var(--color-border2);
    margin-bottom: 4px;
  }

  &__delimiter {
    height: 15px;
  }

  &__menu-item-link {
    margin: 0 4px 3px 4px;
    text-decoration: none !important;
    flex-shrink: 0;
  }

  &__menu-item {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    border-radius: 4px;
    padding: 7px 15px;
    color: var(--color-text-link);
    font-weight: normal;

    &_active {
      background-color: var(--color-bg-hover);
      color: var(--color-text-in-hover);
    }

    &:hover {
      background-color: var(--color-bg-hover);
      color: var(--color-text-in-hover);
    }
  }

  &__menu-item-icon {
    flex-shrink: 0;
    width: 17px;
    color: var(--color-text1);

    body.left-menu-short & {
      width: 17px;
    }
  }

  &__menu-item-text-wrapper {
    body.left-menu-short & {
      display: none;
    }

    overflow-x: hidden;
  }

  &__menu-item &__menu-item-text {
    margin-left: 12px;
  }

  &__menu-item-text {
    display: block;
    font-weight: initial;
    transition: opacity 0.3s ease;

    body.left-menu-short & {
      opacity: 0;
    }
  }

  &__menu-sub-item {
    flex-shrink: 0;
    display: block;
    padding: 0 5px 0 52px;
    margin-bottom: 10px;
    font-size: 0.8rem;
    transition: opacity 0.3s ease;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;

    body.left-menu-short & {
      opacity: 0;
    }
  }
}
</style>
