<template>
  <div class="full-width full-height relative-position">
    <q-card class="container">
      <q-table
        class="tariffs__table"
        ref="tableRef"
        hide-bottom
        :loading="loading"
        :rows="packagesList"
        :columns="columns"
        :pagination="pagination"
        @request="onRequest"
        separator="cell"
      >
        <template #top>
          <div class="row justify-between items-center full-width">
            <div class="q-table__title">
              Пакеты
            </div>
            <div>
              <q-btn @click="toggleModal(true, 'addPackage')">Создать пакет</q-btn>
            </div>
          </div>
        </template>
        <template #body-cell-actions="item">
          <q-td class="relative-position overflow-hidden">
            <q-btn
              size="xs"
              round
              icon="list"
              flat
              class="cursor-pointer"
              @click="activeContext = item.key"
            >
              <q-menu
                transition-show="jump-down"
                transition-hide="jump-up"
                :offset="[50, 10]"
              >
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'editPackage', item)"
                  >
                    <q-item-section>
                      Редактировать
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </q-card>
    <form-tariff-modal
      :isOpenModal="isOpenModal"
      :activeModalName="activeModalName"
      :activeItem="activeItem"
      @hide="toggleModal(false)"
      @close="toggleModal(false)"
      @upd-list="fetchPackagesList"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { apiConstants, requests } from '../../api';
import FormTariffModal from '@/components/Modals/FormPackageModal';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();

/// Refs ///
const loading = ref(true);

const tableRef = ref();

const packagesList = ref([]);

const columns = ref([
  { align: 'left', name: 'title', field: 'title', label: 'Название' },
  { align: 'left', name: 'is_active', field: item => item.is_active ? 'Да' : 'Нет', label: 'Доступен (да/нет)' },
  { align: 'left', name: 'price', field: 'price', label: 'Цена' },
  { align: 'left', name: 'discount', field: 'discount', label: 'Скидка' },
  { align: 'left', name: 'type', field: item => getPackageType(item.type), label: 'Тип' },
  { align: 'left', name: 'storage', field: item => item.properties.storage, label: 'Количество пространства' },
  { align: 'left', name: 'user', field: item => item.properties.users, label: 'Количество пользователей' },
  { align: 'left', name: 'actions' }
]);

const pagination = ref({
  rowsPerPage: 50
});

// Modals data
const activeItem = ref({});
const isOpenModal = ref(false);
const activeModalName = ref('');

/// Handlers ///
const fetchPackagesList = async (page) => {
  loading.value = true;

  let endpoint = apiConstants.PACKAGES.LIST;
  if (page) {
    endpoint += `?page=${page}`;
  }

  try {
    const { data: packagesResponse } = await requests.get(endpoint);
    packagesList.value = packagesResponse.data;
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const setActiveItem = (item) => {
  activeItem.value = item;
};

const toggleModal = (status, modalName = '', item = {}) => {
  isOpenModal.value = status;
  activeModalName.value = modalName;
  setActiveItem(item);
};

const onRequest = async (props) => {
  await fetchPackagesList();
};

/// Hooks ///
onMounted(() => {
  tableRef.value.requestServerInteraction();
});

/// Utils ///

/**
 * Get description for package type
 * @param type Type of package
 * @returns {string}
 */
function getPackageType (type) {
  switch (type) {
    case 'disk':
      return 'Диск';
    default:
      return 'Пользователи';
  }
}
</script>

<style lang="scss" scoped>
</style>
