<template>
  <q-dialog v-model="isOpenModal">
    <q-card :class="{ 'full-width': activeModalName === 'create-user' }">
      <template v-if="modalsNameForUserActions.includes(activeModalName)">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">{{ modalContent.title }}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section v-if="activeModalName === 'editUserModal'">
          <q-input
            v-model="v$.name.$model"
            :error="v$.name.$error"
            :error-message="getErrorMessage('name')"
            label="Логин"
            class="col col-md-6 q-mb-sm"
            filled
          />
          <q-input
            v-model="v$.email.$model"
            :error="v$.email.$error"
            :error-message="getErrorMessage('email')"
            label="Еmail"
            class="col col-md-6"
            filled
          />
        </q-card-section>
        <q-card-actions>
          <q-btn type="submit" color="primary" no-caps @click="submit(activeItem)">{{
            modalContent.action
          }}</q-btn>
        </q-card-actions>
      </template>
      <template v-else>
        <create-user-form @close="toggleModal" />
      </template>
    </q-card>
  </q-dialog>
</template>

<script setup>
import CreateUserForm from '@/components/Forms/CreateUserForm.vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { apiConstants, requests } from '@/api';
import { computed, ref, defineEmits, defineExpose, defineProps, watch } from 'vue';

const emit = defineEmits([
  'on-update'
]);

const $q = useQuasar();
const loader = useLoader();

const modalsNameForUserActions = [
  'editUserModal',
  'removeAdmin',
  'addAdmin',
  'resetUserPassword',
  'deleteUserAvatar',
  'deleteUser'
];

const activeModalName = ref('');

const resetUserInfo = ref({
  name: '',
  email: ''
});

const modalContent = computed(() => {
  let content = {};
  switch (activeModalName.value) {
    case 'editUserModal':
      content = {
        title: 'Изменить email или логин',
        action: 'Сохранить'
      };

      break;
    case 'removeAdmin':
      content = {
        title: 'Убрать пользователя из администраторов?',
        action: 'Убрать'
      };
      break;
    case 'addAdmin':
      content = {
        title: 'Дать пользователю права администратора?',
        action: 'Добавить'
      };
      break;
    case 'resetUserPassword':
      content = {
        title: 'Сбросить пароль?',
        action: 'Сбросить'
      };
      break;
    case 'deleteUserAvatar':
      content = {
        title: 'Удалить аватар?',
        action: 'Удалить'
      };
      break;
    case 'deleteUser':
      content = {
        title: 'Удалить пользователя?',
        action: 'Удалить'
      };
      break;
  }
  return content;
});

// validations
const rules = computed(() => {
  return {
    name: {
      required
    },
    email: {
      required,
      email
    }
  };
});

const v$ = useVuelidate(rules, resetUserInfo, { $autoDirty: true });

const getErrorMessage = (field) => {
  return v$.value[field].$errors[0]?.$message;
};

async function submit (item) {
  let endpoint = '';
  const itemId = item.id;
  switch (activeModalName.value) {
    case 'editUserModal': {
      const isFormCorrect = await v$.value.$validate();
      if (!isFormCorrect) {
        return;
      }
      endpoint = apiConstants.USERS.CHANGE_INFO(itemId);
      break;
    }
    case 'removeAdmin':
      endpoint = apiConstants.USERS.REMOVE_ADMIN(itemId);
      break;
    case 'addAdmin':
      endpoint = apiConstants.USERS.ADD_ADMIN(itemId);
      break;
    case 'resetUserPassword':
      endpoint = apiConstants.USERS.RESET_PASSWORD(itemId);
      break;
    case 'deleteUserAvatar':
      endpoint = apiConstants.USERS.DELETE_AVATAR(itemId);
      break;
    case 'deleteUser':
      endpoint = apiConstants.USERS.DELETE_USER(itemId);
      break;
  }

  loader.show(500);
  let response = {};
  let hasError = false;
  try {
    if (activeModalName.value === 'editUserModal') {
      response = await requests.patch(endpoint, resetUserInfo.value);
      $q.notify({
        message: `Данные пользователя обновлены! <br>
                  Логин: ${response.data.data.name} <br>
                  Email: ${response.data.data.email} <br>`,
        color: 'green'
      });
      clearResetUserInfo();
    } else if (activeModalName.value === 'deleteUser') {
      response = await requests.delete(endpoint);
      $q.notify({
        message: response.data.message,
        color: 'green'
      });
    } else {
      response = await requests.patch(endpoint);
      $q.notify({
        // TODO: пока что неизвестно, что придет после успешного удаления аватарки.
        // В будущем следует проверить правильность уведомления
        message: response.data?.password
          ? `Новый пароль: ${response.data?.password}`
          : response.data.message,
        color: 'green'
      });
    }

    v$.value.$reset();
    emit('on-update', { event: activeModalName.value, response: response.data.data });
  } catch (error) {
    hasError = true;
    $q.notify({
      message: error.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
    if (!hasError) {
      toggleModal();
    }
  }
}

function clearResetUserInfo () {
  resetUserInfo.value.name = '';
  resetUserInfo.value.email = '';
}

// Modals actions
const activeItem = ref({});
const isOpenModal = ref(false);

watch(activeModalName, () => {
  if (activeModalName.value === 'editUserModal') {
    resetUserInfo.value = {
      name: activeItem.value.name,
      email: activeItem.value.email
    };
  }
});

function toggleModal (modalName = '', item = {}) {
  isOpenModal.value = !isOpenModal.value;
  activeModalName.value = modalName;
  setActiveItem(item);
}

function setActiveItem (item) {
  activeItem.value = item;
}

defineExpose({
  toggleModal
});
</script>
