export default {
  AUTH: {
    LOGIN: '/user/get_token'
  },
  PROJECTS: {
    LIST: '/admin/projects',
    EDIT_TARIFF: (id) => `admin/projects/${id}/attach_tariff`
  },
  USERS: {
    LIST: '/admin/users', // get, post - для создания пользователя
    GET_USER_DATA: (id) => `admin/users/${id}`,
    DELETE_USER: (id) => `admin/users/${id}`, // delete
    RESET_PASSWORD: (id) => `admin/users/${id}/password_reset`, // patch
    CHANGE_INFO: (id) => `admin/users/${id}/change_info`, // patch
    DELETE_AVATAR: (id) => `admin/users/${id}/delete_avatar`, // patch
    GET_AUTHOR: (id) => `admin/users/${id}`, // get
    ADD_ADMIN: (id) => `admin/users/${id}/make_admin`, // PATCH
    REMOVE_ADMIN: (id) => `admin/users/${id}/remove_admin` // PATCH
  },
  PACKAGES: {
    LIST: '/admin/packages', // GET
    ADD: '/admin/packages', // POST
    CHANGE: (id) => `/admin/packages/${id}` // PUT
  },
  WORKSPACES: {
    LIST: '/admin/workspaces', // GET
    INFO: (id) => `/admin/workspaces/${id}`, // POST, PUT (edit), DELETE (delete)
    UPDATE_FROZEN: (id) => `/admin/workspaces/${id}/update_frozen`, // PATCH
    UPDATE_TRIAL: (id) => `/admin/workspaces/${id}/update_trial`, // PATCH
    UPDATE_DISCOUNT: (id) => `/admin/workspaces/${id}/update_discount`, // PATCH
    SAVE_SUBSCRIPTION: (id) => `/admin/workspaces/${id}/subscription`, // POST
    SAVE_USER_COUNT: (id) => `/admin/workspaces/${id}/subscription/decrement`, // POST
    CHANGES: (id) => `/admin/workspaces/${id}/subscription/updates` // GET, DELETE
  },
  PROMOCODES: {
    LIST: '/admin/promocodes', // GET
    ADD: '/admin/promocodes', // POST
    CHANGE: (id) => `/admin/promocodes/${id}`, // PUT
    DELETE: (id) => `/admin/promocodes/${id}` // DELETE
  },
  INVOICES: {
    LIST: '/admin/invoices', // GET
    COMPLETE: (id) => `/admin/invoices/${id}/complete`, // PATCH
    DELETE: (id) => `/admin/invoices/${id}` // DELETE
  },
  LICENSES: {
    LIST: '/admin/licenses', // GET
    ADD: '/admin/licenses', // POST
    CHANGE: (id) => `/admin/licenses/${id}`, // PUT
    DELETE: (id) => `/admin/licenses/${id}`, // DELETE
    DOWNLOAD: (id) => `/admin/licenses/${id}/download` // GET
  },
  TARIFFS: {
    LIST: '/tariffs', // get (list), post (create one)
    TARIFF: (id) => `/tariffs/${id}` // get, put (edit), delete
  },
  FEEDBACK: {
    LIST: '/admin/feedbacks', // GET
    DELETE: (id) => `/admin/feedbacks/${id}`, // DELETE
    COMPLETE: (id) => `/admin/feedbacks/${id}/complete`, // PATCH
    UNCOMPLETE: (id) => `/admin/feedbacks/${id}/uncomplete` // PATCH
  },
  DASHBOARD: {
    LIST: '/admin/dashboard/' // GET
  },
  // CONTENT
  BLOG: {
    CATEGORIES: {
      LIST: 'admin/blog/categories', // GET, POST (need add category_id)
      UPDATE: (id) => `admin/blog/categories/${id}` // PUT, DELETE
    },
    POSTS: {
      LIST: 'admin/blog/posts', // GET, POST
      UPDATE: (id) => `admin/blog/posts/update/${id}`, // POST
      DELETE: (id) => `admin/blog/posts/${id}` // DELETE
    }
  },
  REVIEWS: {
    LIST: 'admin/reviews', // GET, POST
    UPDATE: (id) => `admin/blog/posts/update/${id}`, // POST
    DELETE: (id) => `admin/reviews/${id}` // DELETE
  }
};
