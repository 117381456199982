<template>
  <div class="full-width full-height relative-position">
    <q-card class="container">
      <q-table
        class="workspaces__table"
        ref="tableRef"
        :loading="loading"
        :rows="workspacesList"
        :columns="columns"
        :pagination="pagination"
        @request="onRequest"
        hide-pagination
        separator="cell"
      >
        <template #top>
          <div class="row justify-between items-center full-width">
            <div class="q-table__title">
              Пространства
            </div>
            <div class="filter row justify-end items-center no-wrap">
              <q-select
                class="col-12"
                borderless
                v-model="filter.id"
                :options="filter.options"
                label="Подписка"
              />
              <q-input
                class="col-12"
                placeholder="Поиск пространства"
                dense debounce="300" color="primary" v-model="filter.search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </div>
          </div>
        </template>
        <template #body-cell-author="item">
          <q-td class="relative-position overflow-hidden orange-clickable-text" @click="userDetailModal.toggleModal('user', item.row.author)">
            {{ item.row.author.name }}
          </q-td>
        </template>
        <template #body-cell-subscription="item">
          <q-td class="relative-position overflow-hidden">
            <template v-if="!item.row.subscription">-</template>
            <template v-else>
              <div>
                {{ item.row.subscription.isActive ? 'Активная' : 'Неактивная' }}
              </div>
              <div v-if="!item.row.subscription.trialExpired">
                Пробный период: {{ item.row.subscription.finishTrial }}
              </div>
              <div v-if="item.row.subscription.finish">
                Окончание: {{ item.row.subscription.finish }}
              </div>
            </template>
          </q-td>
        </template>
        <template #body-cell-actions="item">
          <q-td class="relative-position overflow-hidden">
            <q-btn
              size="xs"
              round
              icon="list"
              flat
              class="cursor-pointer"
              @click="activeContext = item.key"
            >
              <q-menu
                transition-show="jump-down"
                transition-hide="jump-up"
                :offset="[50, 10]"
              >
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal(true, 'showInfo', item.row)"
                  >
                    <q-item-section>
                      Подробнее
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </q-card>
    <div class="row justify-center q-mt-md">
      <q-pagination
        v-model="currentPage"
        color="grey-8"
        :max="pagination.max"
        size="sm"
      />
    </div>
    <workspaces-modal
      :isOpenModal="isOpenModal"
      :activeModalName="activeModalName"
      :activeItem="activeItem"
      :subscriptionConfigFromList="subscriptionConfig"
      @hide="toggleModal(false)"
      @close="toggleModal(false)"
      @upd-list="updListItem"
      @delete="deleteListItem"
    />
    <user-detail-modal ref="userDetailModal" />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, reactive } from 'vue';
import { apiConstants, requests } from '../../api';
import WorkspacesModal from '@/components/Modals/WorkspacesModal';
import UserDetailModal from '@/components/Modals/UserDetailModal.vue';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();
const userDetailModal = ref(null);

/// Refs ///
const loading = ref(true);
const filter = reactive({
  options: [
    {
      label: 'Все',
      value: 'all'
    },
    {
      label: 'Активная',
      value: 'active'
    },
    {
      label: 'Не активная',
      value: 'inactive'
    },
    {
      label: 'Триал',
      value: 'trial'
    }
  ],
  id: {
    label: 'Все',
    value: 'all'
  },
  search: ''
});

const tableRef = ref();
const pagination = ref({
  sortBy: 'desc',
  descending: false,
  rowsPerPage: 50,
  max: 1
});
const currentPage = ref(1);

const workspacesList = ref([]);

const columns = ref([
  { align: 'left', name: 'id', field: 'id', label: 'ID' },
  { align: 'left', name: 'title', field: 'title', label: 'Название' },
  { align: 'left', name: 'projects', field: 'projects', label: 'Проекты' },
  { align: 'left', name: 'users', field: 'users', label: 'Пользователи' },
  // { align: 'left', name: 'payment_type', field: row => getPaymentType(row.payment_type), label: 'Тип' },
  // { align: 'left', name: 'balance', field: row => row.balance ?? '—', label: 'Баланс' },
  { align: 'left', name: 'author', field: 'author', label: 'Автор' },
  // TODO: need complete
  { align: 'left', name: 'subscription', field: 'subscription', label: 'Подписка' },
  { align: 'left', name: 'is_frozen', field: item => item.is_frozen ? 'Да' : 'Нет', label: 'Заморожен? (Да/Нет)' },
  { align: 'left', name: 'actions' }
]);

// Modals data
const activeItem = ref({});
const subscriptionConfig = ref({});
const isOpenModal = ref(false);
const activeModalName = ref('');

watch(filter, async v => {
  loading.value = true;
  const endpoint = apiConstants.WORKSPACES.LIST + `?filter[title]=${v.search}&filter[subscribe]=${v.id.value}`;

  try {
    const { data: workspacesResponse } = await requests.get(endpoint);
    workspacesList.value = workspacesResponse.data;
    subscriptionConfig.value = workspacesResponse.subscriptionConfig;
    // Meta
    pagination.value.rowsPerPage = workspacesResponse.meta.per_page;
    pagination.value.max = workspacesResponse.meta.last_page;
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
});

/// Handlers ///
const fetchWorkspaceList = async (page) => {
  loading.value = true;

  let endpoint = apiConstants.WORKSPACES.LIST;
  if (page) {
    endpoint += `?page=${page}`;
  }

  try {
    const { data: workspacesResponse } = await requests.get(endpoint);
    workspacesList.value = workspacesResponse.data;
    subscriptionConfig.value = workspacesResponse.subscriptionConfig;
    // Meta
    pagination.value.rowsPerPage = workspacesResponse.meta.per_page;
    pagination.value.max = workspacesResponse.meta.last_page;
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    console.error(e);
  } finally {
    loading.value = false;
  }
};

/// Watch ///
watch(currentPage, async () => {
  await fetchWorkspaceList(currentPage.value);
});

const setActiveItem = (item) => {
  activeItem.value = item;
};

const updListItem = (item) => {
  const oldItemIdx = workspacesList.value.findIndex((workspace) => workspace.id === item.id);
  workspacesList.value[oldItemIdx] = item;
  setActiveItem(item);
};

const deleteListItem = (item) => {
  workspacesList.value = workspacesList.value.filter(w => w.id !== item.id);
};

const toggleModal = (status, modalName = '', item = {}) => {
  isOpenModal.value = status;
  activeModalName.value = modalName;
  setActiveItem(item);
};

const onRequest = async (props) => {
  await fetchWorkspaceList();
};

/// Hooks ///
onMounted(() => {
  tableRef.value.requestServerInteraction();
});
</script>

<style lang="scss" scoped>
.filter {
  gap: 12px;
}

.workspaces__table {
  height: calc(100vh - 100px);

  &:deep(.q-table__top,
    .q-table__bottom,
    thead tr:first-child th) {
    background-color: #fff;
  }

  &:deep(thead tr th) {
    position: sticky;
    background-color: #fff;
    z-index: 1;
  }

  &:deep(thead tr:first-child th) {
    top: 0;
  }

  &:deep(&.q-table--loading thead tr:last-child th) {
    top: 48px
  }

  &:deep(tbody) {
    scroll-margin-top: 48px
  }
}

.orange-clickable-text {
  color: orange;
  cursor: pointer;
}
</style>
