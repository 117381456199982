<template>
  <div class="changes">
    <q-table
      :rows="changes"
      :columns="columns"
    >
      <template #top>
        <div class="row">
          <div class="q-table__title">
            Изменения
          </div>
        </div>
      </template>
      <template #body-cell-start-changes="item">
        <q-td>
          <template v-if="item.startChanges">{{ item.startChanges }}</template>
          <template v-else>-</template>
        </q-td>
      </template>
      <template #body-cell-actions="item">
        <q-td>
          <q-btn
            size="xs"
            round
            icon="delete"
            flat
            class="cursor-pointer"
            @click="toggleModal(item.row)"
          ></q-btn>
        </q-td>
      </template>
    </q-table>

    <q-dialog v-model="isOpenModalRemove">
      <q-card>
        <q-card-section>
          <div class="text-h6">Удалить запись?</div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            label="OK"
            color="primary"
            @click="removeChange"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { apiConstants, requests } from '../../api';

const props = defineProps({
  workspace: {
    type: Object,
    required: true
  }
});

const $q = useQuasar();
const loader = useLoader();

const columns = ref([
  { align: 'left', name: 'users', field: 'users', label: 'Пользователи' },
  { align: 'left', name: 'period', field: 'period', label: 'Период' },
  { align: 'left', name: 'amount', field: 'amount', label: 'Доплата' },
  { align: 'left', name: 'status', field: 'status', label: 'Статус' },
  { align: 'left', name: 'start-changes', field: 'startChanges', label: 'Дата применения' },
  { align: 'left', name: 'actions' }
]);

const changes = ref([]);

const getList = async () => {
  loader.show(500);

  try {
    const endpoint = apiConstants.WORKSPACES.CHANGES(props.workspace.id);
    const response = await requests.get(endpoint);

    changes.value = response.data.data;
  } catch (e) {
    $q.notify({
      message: e.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
  }
};

const activeItem = ref({});
const isOpenModalRemove = ref(false);

const toggleModal = (item) => {
  activeItem.value = item;
  isOpenModalRemove.value = true;
};

const removeChange = async () => {
  loader.show(500);

  try {
    const endpoint = apiConstants.WORKSPACES.CHANGES(props.workspace.id) + `/${activeItem.value.id}`;
    const response = await requests.delete(endpoint);

    $q.notify({
      message: response.data.message,
      color: 'green'
    });

    changes.value = changes.value.filter((item) => item.id !== activeItem.value.id);
    activeItem.value = {};
    isOpenModalRemove.value = false;
  } catch (e) {
    $q.notify({
      message: e.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
  }
};

getList();

</script>

<style lang="scss" scoped></style>
