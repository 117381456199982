<template>
  <q-dialog v-model="isOpen">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ modalContent.title }}</div>
        <q-space />
        <q-btn
          icon="close"
          flat
          round
          dense
          v-close-popup
          @click="emit('close')"
        />
      </q-card-section>
      <q-card-section>
        <div class="modal__content">
          {{ modalContent.text }}
        </div>
      </q-card-section>
      <q-card-actions>
        <q-btn
          color="blue-grey"
          no-caps
          @click="$emit('close')"
        >
          {{ modalContent.cancel }}
        </q-btn>
        <q-btn
          :color="activeModalName === 'deleteItem' ? 'red' : 'primary'"
          no-caps
          @click="submit"
        >
          {{ modalContent.action }}
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { computed, watch, defineProps, defineEmits } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { apiConstants, requests } from '../../api';

const $q = useQuasar();
const loader = useLoader();

const props = defineProps({
  isOpenModal: {
    type: Boolean,
    default: false
  },
  activeModalName: {
    type: String,
    default: ''
  },
  activeItem: {
    type: Object,
    default: () => ({})
  },
  itemId: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['close', 'upd-list', 'update:isOpenModal']);

const isOpen = computed({
  get: () => props.isOpenModal,
  set: (value) => emit('update:isOpenModal', value)
});

const modalContent = computed(() => {
  switch (props.activeModalName) {
    case 'markAsRead':
      return {
        title: 'Отметить счет прочитанным?',
        text: 'Вы действительно хотите отметить данный счет как прочитанный?\nДанное действие невозможно отменить.',
        action: 'Отметить прочитанным',
        cancel: 'Отменить'
      };
    case 'deleteItem':
      return {
        title: 'Удаление счета',
        text: 'Вы действительно хотите удалить данный счет?\nДанное действие невозможно отменить.',
        action: 'Удалить счет',
        cancel: 'Отменить'
      };
    default:
      return {};
  }
});

watch(
  () => props.isOpenModal,
  async () => {
    isOpen.value = props.isOpenModal;
  }
);

const submit = async () => {
  loader.show();

  let endpoint = '';

  let response = {};
  let isErrorRequest = false;

  try {
    switch (props.activeModalName) {
      case 'deleteItem':
        endpoint = apiConstants.INVOICES.DELETE(props.itemId);
        response = await requests.delete(endpoint);
        break;
      default:
        endpoint = apiConstants.INVOICES.COMPLETE(props.itemId);
        response = await requests.patch(endpoint);
        break;
    }

    $q.notify({
      message: response.data.message,
      color: 'green'
    });

    emit('upd-list');
  } catch (error) {
    isErrorRequest = true;
    $q.notify({
      message: error.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
    if (!isErrorRequest) {
      emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__content {
    white-space: pre-wrap;
  }
}
</style>
