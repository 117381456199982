<template>
  <div class="row items-center justify-center">
    <q-form
        style="min-width: 400px"
        ref="form"
        @submit="sendForm"
    >
      <h2 class="text-center">Логин</h2>
      <q-input
          v-model="formData.email"
          class="mb-4"
          label="E-mail"
      />
      <q-input
          v-model="formData.password"
          class="mb-4"
          type="password"
          label="Пароль"
      />
      <div class="row justify-between">
        <q-btn type="submit" label="Войти" class="mb-8"/>
        <q-btn label="Зарегистрироваться" class="mb-8"/>
      </div>
    </q-form>
  </div>
</template>

<script setup>

import { ref } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
const store = useStore();
const formData = ref({
  email: '',
  password: ''
});

const sendForm = async () => {
  await store.dispatch('auth/login', formData.value);
  await router.push({ name: 'Home' });
};

</script>
