<template>
  <div class="subscription">

    <div
      class="subscription__forms"
      v-if="!subscription"
    >
      <div class="subscription__fields">
        <div>
          <q-input
            v-model="usersCount"
            type="number"
            label="Количество пользователей"
          ></q-input>

          <q-toggle
            v-model="period"
            true-value="year"
            false-value="month"
            :label="`Подписка на год (-${earlyDiscount}%)`"
          />
        </div>
      </div>

      <div class="subscription__prices">
        <div class="subscription__price">
          <div>{{ total.group }}</div>
          <span>Общая стоимость за год</span>
        </div>
        <div class="subscription__price">
          <div>{{ total.single }}</div>
          <span>Стоимость за одного пользователя в месяц</span>
        </div>
        <div
          class="subscription__price"
          v-if="subscription?.discount"
        >
          <div>{{ subscription.discount }}</div>
          <span>Индивидуальная скидка</span>
        </div>
      </div>

      <q-card-actions>
        <q-btn
          type="submit"
          color="primary"
          no-caps
          @click="saveSubscription"
        >Сохранить</q-btn>
      </q-card-actions>
    </div>

    <div
      v-else
      class="subscription__info"
    >
      <table>
        <tbody>
          <template
            v-for="(item, idx) in subscribeInfo"
            :key="idx"
          >
            <tr v-if="item.value">
              <td>
                {{ item.label }}
              </td>
              <td>
                {{ item.value }}

                <template v-if="item.label === 'Количество пользователей'">
                  <span
                    class="subscription__pseudo-link"
                    @click="toggleModal('changeUsersCount')"
                  >Уменьшить количество пользователей</span>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <q-dialog v-model="isOpenChangeUsersCountModal">
      <q-card>
        <q-card-section>
          <div class="text-h6">Изменение количества пользователей</div>
        </q-card-section>
        <q-card-section>
          <q-input
            v-model="usersCountForForm"
            type="number"
            label="Количество пользователей"
          ></q-input>
          <!-- :max="props.subscription?.users" -->
        </q-card-section>

        <q-card-actions>
          <q-btn
            flat
            label="OK"
            color="primary"
            @click="saveUserCount"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import { useQuasar } from 'quasar';
import { useLoader } from '@/composables/useLoader.js';
import { apiConstants, requests } from '../../api';

/// Emits ///
const emit = defineEmits([
  'update-list',
  'upd-subscription'
]);

/// Props ///
const props = defineProps({
  workspace: {
    type: Object,
    required: true
  },
  // информация о подписке пространства
  subscription: {
    type: Object,
    required: false
  },
  // общая информация для всех подписок
  subscriptionConfig: {
    type: Object,
    required: false
  },
  // возможно не нужен
  isCanRenewal: {
    type: Boolean,
    required: false
  }
});

const $q = useQuasar();
const loader = useLoader();

const usersCount = ref(1);
const usersCountForForm = ref(props.subscription?.users ?? 1);
const period = ref('year');

const earlyDiscount = computed(() => props.subscriptionConfig?.yearly_discount * 100);

// Рассчет суммы за пользователя
const calculatePrice = (count) => {
  const steps = props.subscriptionConfig?.calculate.steps;
  const intervals = props.subscriptionConfig?.calculate.intervals;
  const basePrice = props.subscriptionConfig.base_price;
  const yearlyDiscount = props.subscriptionConfig.yearly_discount;

  let countUsers = count;
  let oneUserDiscount = 0;

  while (countUsers > 0) {
    let rightOrder = null;

    for (let key = 0; key < intervals.length; key++) {
      const value = intervals[key];
      if (countUsers > value) {
        rightOrder = key + 1;
      }
    }

    if (rightOrder !== null && steps[rightOrder] !== undefined) {
      oneUserDiscount += steps[rightOrder];
    }

    countUsers--;
  }

  // Рассчитываем сумму за 1 пользователя
  return period.value === 'year'
    ? (basePrice - oneUserDiscount) * (1 - +yearlyDiscount)
    : basePrice - oneUserDiscount;
};

// Сумма за 1 пользователя и общая сумма за год
const total = computed(() => {
  const totalPrice = calculatePrice(usersCount.value);

  return {
    single: totalPrice.toLocaleString(),
    group: (
      totalPrice *
      usersCount.value *
      (period.value === 'year' ? 12 : 1)
    ).toLocaleString()
  };
});

// Собираем данные для блока "Активная подписка"
const subscribeInfo = computed(() => {
  const fields = [
    {
      label: 'Статус',
      value: props.subscription.isActive ? 'Активна' : 'Не активна',
      classes: props.subscription.isActive
        ? 'card-info__value--active'
        : 'card-info__value--inactive'
    },
    {
      label: 'Количество пользователей',
      value: `${props.workspace.users} / ${props.subscription.users}`
    },
    // {
    //   label: 'Дисковое пространство',
    //   value: `${(props.workspace.storage / 1024).toFixed(2)} / ${(
    //     props.subscription.storage / 1024
    //   ).toFixed(2)} GB`
    // },
    {
      label: 'Дисковое пространство',
      value: `${(0 / 1024).toFixed(2)} / ${(
        props.subscription.storage / 1024
      ).toFixed(2)} GB`
    },
    { label: 'Дата окончания', value: props.subscription.finish }
  ];

  // Если пробный период активен, то показываем поле с датой окончания
  if (!props.subscription.trialExpired) {
    fields.push({
      label: 'Пробный период',
      value: `до ${props.subscription.finishTrial}`
    });
  }

  return fields;
});

const saveSubscription = async () => {
  loader.show(500);

  try {
    const endpoint = apiConstants.WORKSPACES.SAVE_SUBSCRIPTION(props.workspace.id);
    const response = await requests.post(endpoint, {
      users_count: usersCount.value,
      period: period.value
    });

    emit('update-list', response.data.data);
    $q.notify({
      message: response.data.message,
      color: 'green'
    });
  } catch (e) {
    $q.notify({
      message: e.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
  }
};

const isOpenChangeUsersCountModal = ref(false);

const toggleModal = (modalName) => {
  if (modalName === 'changeUsersCount') {
    isOpenChangeUsersCountModal.value = true;
  }
};

const saveUserCount = async () => {
  loader.show(500);

  try {
    const endpoint = apiConstants.WORKSPACES.SAVE_USER_COUNT(props.workspace.id);
    const response = await requests.post(endpoint, {
      users: +usersCountForForm.value
    });

    $q.notify({
      message: response.data.message,
      color: 'green'
    });

    emit('update-list', response.data.data);
    isOpenChangeUsersCountModal.value = false;
  } catch (e) {
    $q.notify({
      message: e.response.data.message,
      color: 'red'
    });
  } finally {
    loader.hide();
  }
};
</script>

<style lang="scss" scoped>
.subscription {
  &__fields {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 60px;

    >div {
      display: flex;
      gap: 40px;
    }
  }

  &__prices {
    display: flex;
    gap: 40px;
    margin-bottom: 50px;
  }

  &__price {
    >div {
      font-size: 20px;
    }
  }

  &__info {
    max-width: 650px;

    table {
      td {
        padding: 5px 30px;
      }
    }
  }

  &__pseudo-link {
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
    margin-left: 30px;
  }
}
</style>
