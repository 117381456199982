
const getters = {
  getProjectList (state) {
    return state.projectList;
  },
  getTariffList (state) {
    return state.tariffList;
  }
};
export default getters;
