<template>
  <div class="full-width full-height relative-position">
    <q-card class="container">
      <q-table
        class="users__table"
        ref="tableRef"
        v-model:pagination="pagination"
        :rows-per-page-options="[pagination.rowsPerPage]"
        :loading="loading"
        :rows="usersList"
        :columns="columns"
        @request="onRequest"
        separator="cell"
      >
        <template #top>
          <div class="q-table__control row justify-between full-width">
            <div class="q-table__title">Пользователи</div>
            <div class="control row justify-end items-center no-wrap">
              <q-input
                class="col-8"
                placeholder="Поиск пользователя"
                dense
                debounce="300"
                color="primary"
                v-model="search"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
              <q-btn class="col-7 ml-4" @click="updateUserModal.toggleModal('create-user')"
                >Создать пользователя</q-btn
              >
            </div>
          </div>
        </template>
        <template #body-cell-name="item">
          <q-td class="relative-position overflow-hidden" @click="userDetailModal.toggleModal('user', item.row)">
            <div v-show="item.row.is_admin" class="user-badge">Админ</div>
            <span class="orange-clickable-text">{{ item.row.name }}</span>
          </q-td>
        </template>
        <template #body-cell-actions="item">
          <q-td class="relative-position overflow-hidden">
            <q-btn
              size="xs"
              round
              icon="list"
              flat
              class="cursor-pointer"
              @click="activeContext = item.key"
            >
              <q-menu
                transition-show="jump-down"
                transition-hide="jump-up"
                :offset="[50, 10]"
              >
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="updateUserModal.toggleModal('editUserModal', item.row)"
                  >
                    <q-item-section> Редактировать </q-item-section>
                  </q-item>
                </q-list>
                <q-list v-if="item.row.is_admin" style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="updateUserModal.toggleModal('removeAdmin', item.row)"
                  >
                    <q-item-section> Убрать админа </q-item-section>
                  </q-item>
                </q-list>
                <q-list v-else style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="updateUserModal.toggleModal('addAdmin', item.row)"
                  >
                    <q-item-section> Добавить админа </q-item-section>
                  </q-item>
                </q-list>
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="updateUserModal.toggleModal('resetUserPassword', item.row)"
                  >
                    <q-item-section> Сбросить пароль </q-item-section>
                  </q-item>
                </q-list>
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="updateUserModal.toggleModal('deleteUserAvatar', item.row)"
                  >
                    <q-item-section> Удалить аватар </q-item-section>
                  </q-item>
                </q-list>
                <q-list style="min-width: 100px">
                  <q-item
                    clickable
                    v-close-popup
                    @click="toggleModal('deleteUser', item.row)"
                  >
                    <q-item-section> Удалить пользователя </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </q-card>
    <user-detail-modal ref="userDetailModal" @on-update-user="fetchUsersList" />
    <update-user-modal ref="updateUserModal" @on-update="fetchUsersList" />
  </div>
</template>

<script setup>
import UpdateUserModal from '@/components/Modals/UpdateUserModal.vue';
import UserDetailModal from '@/components/Modals/UserDetailModal.vue';
import { ref, onMounted, watch } from 'vue';
import { apiConstants, requests } from '../../api';
import router from '@/router';
import { useStore } from 'vuex';

const store = useStore();

const updateUserModal = ref(null);
const userDetailModal = ref(null);

const search = ref('');

// Table
const loading = ref(true);
const tableRef = ref();
const usersList = ref([]);
const columns = ref([
  { align: 'left', name: 'id', field: 'id', label: '#' },
  { align: 'left', name: 'name', field: 'name', label: 'Имя' },
  { align: 'left', name: 'email', field: 'email', label: 'Email' },
  {
    align: 'left',
    name: 'register_date',
    field: 'register_date',
    label: 'Регистрация'
  },
  { align: 'left', name: 'actions' }
]);

const pagination = ref({
  page: 1,
  rowsPerPage: process.env.VUE_APP_TABLE_ROWS_PER_PAGE,
  rowsNumber: 0
});

watch(search, async (v) => {
  loading.value = true;

  const endpoint = apiConstants.USERS.LIST + `?filter[name]=${v}`;

  try {
    const { data: users } = await requests.get(endpoint);
    usersList.value = users.data;
    const { meta } = users;

    pagination.value.page = meta.current_page;
    pagination.value.rowsPerPage = meta.per_page;
    pagination.value.rowsNumber = meta.total;
  } catch (e) {
    console.log(`${e.response.status}: ${e.response.statusText}`);
  } finally {
    loading.value = false;
  }
});

const fetchUsersList = async (page) => {
  loading.value = true;

  let endpoint = apiConstants.USERS.LIST;
  if (page) {
    endpoint += `?page=${page}`;
  }

  try {
    const { data: users } = await requests.get(endpoint);
    usersList.value = users.data;
    const { meta } = users;

    pagination.value.page = meta.current_page;
    pagination.value.rowsPerPage = meta.per_page;
    pagination.value.rowsNumber = meta.total;
  } catch (e) {
    if (e.response.status === '401') {
      await store.dispatch('auth/logout');
      await router.push({ name: 'Login' });
    }
    console.log(`${e.response.status}: ${e.response.statusText}`);
  } finally {
    loading.value = false;
  }
};

const onRequest = async (props) => {
  await fetchUsersList(props.pagination.page);
};

onMounted(() => {
  tableRef.value.requestServerInteraction();
});
</script>

<style lang="scss" scoped>
.user-badge {
  display: inline-block;
  padding: 2px 8px;
  font-size: 10px;
  background-color: rgb(82, 131, 9);
  color: white;
  border-radius: 2px;
}

.q-dialog__inner--minimized > div {
  max-width: 510px;
}

.control {
  gap: 12px;
}

.orange-clickable-text {
  color: orange;
  cursor: pointer;
}
</style>
